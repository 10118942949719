export  function processWeekRest(records, company, driversDB, isGradski, dateTo) {


    let count = 0;
  
    const arrayLessWeekRest = [];

   
    const findDriver = (id) => {
        let driver = driversDB.find(driver => driver.Id === id)
        return driver.FullName;
    }
    
    function formatLocalTime(date) {
        const inputDate = new Date(date);
        inputDate.setDate(inputDate.getDate()); // Move to the next day
        return inputDate.toISOString(); // Return the next day's date without modifying the time
    }
      //  function formatTextDate(input) {
      // // Parse the input date string into a Date object
      //   let date = new Date(input);

      //   // Format date
      //   let formattedDate = new Intl.DateTimeFormat('en', {
      //       day: '2-digit',
      //       month: '2-digit',
      //       year: 'numeric'
      //   }).format(date);

      //   // Format time
      //   let formattedTime = new Intl.DateTimeFormat('en', {
      //       hour: '2-digit',
      //       minute: '2-digit'
      //   }).format(date);

      //   return formattedDate + ' - ' + formattedTime;
      //   }
    function formatTime(milliseconds) {
        const hours = String(Math.floor(milliseconds / (60 * 60 * 1000))).padStart(2, '0');
        const minutes = String(Math.floor((milliseconds % (60 * 60 * 1000)) / (60 * 1000))).padStart(2, '0');
        return `${hours}:${minutes}`;
      }
    function formatSRBTime(stringDate) {
      const date = new Date(stringDate);
      const formattedDate = `${date.getDate().toString().padStart(2, '0')}.${(date.getMonth() + 1).toString().padStart(2, '0')}.${date.getFullYear()}`;
      return formattedDate
    }
        for(let n=0; n<driversDB.length;n++){
          let driver = driversDB[n];
          let filtered = records.filter(item => item.DriverId === driver.Id) 

              const arrayPossibleWeekRest = [];
              let sumWeekRest = 0;
              let startTime = 0;
              let endTime = 0;

              let minTime;
              if(isGradski){
                minTime = 29 * 60 * 60 * 1000
              } else {
                minTime = 20 * 60 * 60 * 1000
              }
              let lastValue = filtered[filtered.length - 1]
              let firstValue = filtered[0]
            
              for (let i = 0; i < filtered.length; i++) {
                const record = filtered[i];
                const day = record.ActivityDate.split("T")[0];

                const nextRecord = filtered[i + 1];
                const previousRecord = filtered[i - 1];

            
                if (record.Value.toLowerCase() === 'break/rest' || record.Value.toLowerCase() === 'unknown') {
                  //console.log('test1')
                  if(previousRecord) {
                    if(previousRecord.Value.toLowerCase() === 'work'){
                      sumWeekRest = 0;
                      startTime = 0;
                      endTime = 0;
                    }
                    if(previousRecord.Value.toLowerCase() === 'driving'){
                      sumWeekRest = 0;
                      startTime = 0;
                      endTime = 0;
                    }
                    if(previousRecord.Value.toLowerCase() === 'availability'){
                      sumWeekRest = 0;
                      startTime = 0;
                      endTime = 0;
                    }
                    if(previousRecord.Value.toLowerCase() === 'break/rest') {
                      if(previousRecord.Value.toLowerCase() === record.Value.toLowerCase()){
                        if(previousRecord.ActivityDate.split("T")[0] === record.ActivityDate.split("T")[0]){
                          sumWeekRest = 0;
                          startTime = 0;
                          endTime = 0;
                        }
                      }
                    }
                      
                  }
              
                      sumWeekRest += parseInt(record.TotalTime);

                  if(previousRecord) {
                    let previousTime = new Date(previousRecord.EndTime).getTime(); 
                    let nextTime = new Date(record.StartTime).getTime(); 
                    let difference = nextTime - previousTime;
                    if(difference > 24 * 60 * 60 * 1000){
                      //dodavanje vremena ako neka aktivnosti
                      sumWeekRest += difference;
                    }
                  }
                
                  
                  if (startTime === 0) {
                      //console.log('test2')
                    startTime = record.StartTime;
                  }
                  endTime = record.EndTime;

                  if(!nextRecord) {
                    if (sumWeekRest >= minTime) {
                      arrayPossibleWeekRest.push({
                        ActivityDate: day,
                        driver: findDriver(record.DriverId),
                        company: company.Name,
                        startTime: startTime,
                        endTime: endTime,
                        SumTotalWeekRestTime: formatTime(sumWeekRest),
                        Message: 'Nema dovoljni nedeljni odmor!',
                      });

                  
                      sumWeekRest = 0;
                      startTime = 0;
                      endTime = 0;
                    }
                  } else {
                    if(nextRecord.Value.toLowerCase() === 'break/rest') {
                        if(record.Value.toLowerCase() === 'break/rest') {
                          if(nextRecord.ActivityDate.split("T")[0] === record.ActivityDate.split("T")[0]){
                                if (sumWeekRest >= minTime) {
                                  arrayPossibleWeekRest.push({
                                    ActivityDate: day,
                                    driver: findDriver(record.DriverId),
                                    company: company.Name,
                                    startTime: startTime,
                                    endTime: endTime,
                                    SumTotalWeekRestTime: formatTime(sumWeekRest),
                                    Message: 'Nema dovoljni nedeljni odmor!',
                                  });
                      
                                  // Reset the sum and start/end times for the next set of break/rest activities
                                  sumWeekRest = 0;
                                  startTime = 0;
                                  endTime = 0;
                                }
                          }
                        } else if(record.Value.toLowerCase() === 'unknown') {
                          if (sumWeekRest >= minTime) {
                            arrayPossibleWeekRest.push({
                              ActivityDate: day,
                              driver: findDriver(record.DriverId),
                              company: company.Name,
                              startTime: startTime,
                              endTime: endTime,
                              SumTotalWeekRestTime: formatTime(sumWeekRest),
                              Message: 'Nema dovoljni nedeljni odmor!',
                            });
                
                        
                            sumWeekRest = 0;
                            startTime = 0;
                            endTime = 0;
                          }
                        }
                        
                    } else if(record.Value.toLowerCase() === 'unknown'){
                      if(parseInt(record.TotalTime) > 20 * 60 * 60 * 1000) {
                        endTime = record.EndTime;
                      }
                    }
                  }
                  
                } else {
                
                
                  if (startTime !== 0) {
                    
                    if(nextRecord) {
                      if (sumWeekRest >= minTime) {
                        arrayPossibleWeekRest.push({
                          ActivityDate: day,
                          driver: findDriver(record.DriverId),
                          company: company.Name,
                          startTime: startTime,
                          endTime: endTime,
                          SumTotalWeekRestTime: formatTime(sumWeekRest),
                          Message: 'Nema dovoljni nedeljni odmor!',
                        });
            
                        // Reset the sum and start/end times for the next set of break/rest activities
                        sumWeekRest = 0;
                        startTime = 0;
                        endTime = 0;
                      }
                    }
                    // Check if the next record is not 'break/rest' or 'unknown' or 'work' or 'driving'
                    if (!nextRecord) {
                      // Check if the summed break/rest time is greater than 20 hours
                      if (sumWeekRest >= minTime) {
                        arrayPossibleWeekRest.push({
                          ActivityDate: day,
                          driver: findDriver(record.DriverId),
                          company: company.Name,
                          startTime: startTime,
                          endTime: endTime,
                          SumTotalWeekRestTime: formatTime(sumWeekRest),
                          Message: 'Nema dovoljni nedeljni odmor!',
                        });
            
                        // Reset the sum and start/end times for the next set of break/rest activities
                        sumWeekRest = 0;
                        startTime = 0;
                        endTime = 0;
                      }
                    }
                  }
                }
              }
            
                  if(isGradski){
                  
                      let start = 0;
                      let currentCompare33h = 0;
                      for (let i = 0; i < arrayPossibleWeekRest.length; i++) {
                        const record = arrayPossibleWeekRest[i];
                        const previousRecord = arrayPossibleWeekRest[i-1];
                        const nextRecord = arrayPossibleWeekRest[i+1];
                        const [hours, minutes] = record.SumTotalWeekRestTime.split(":").map(Number);
                        const SumTotalWeekRestTimeInMS = (hours * 60 + minutes) * 60 * 1000; 
                          if (SumTotalWeekRestTimeInMS >= 33 * 60 * 60 * 1000) {
                                  if(currentCompare33h == 0) {
                                    start = record.endTime;
                                    currentCompare33h = record;
                                      if(!previousRecord) {
                                      
                                            let zeroDayFrom = new Date(firstValue.StartTime).getTime();
                                            let timeTo = new Date(record.startTime).getTime();
                                            let timeDifference = timeTo - zeroDayFrom

                                            if(timeDifference > 6 * 24 * 60 * 60 * 1000) {
                                              
                                              const newDate = new Date(zeroDayFrom + 6 * 24 * 60 * 60 * 1000);
                                              const dateFromNew = new Date(zeroDayFrom).toISOString()
                                              const formattedDate = formatLocalTime(newDate.toISOString()).split("T")[0];
                                              const formattedTime = formatLocalTime(newDate.toISOString()).split("T")[1].slice(0, -8);
                                              count++
                                              let faultRecord = {
                                                Prekršaj: count,
                                                Vozač: record.driver,
                                                Kompanija: company.Name,
                                                Poruka: `Kasno započet nedelji odmor! Poslednji odmor je ${formatSRBTime(dateFromNew)}, a poćeo naredni ${formatSRBTime(record.startTime)}. Trebao je početi naredni dana ${formatSRBTime(formattedDate)}, vreme ${formattedTime}`,
                                              };
                                              arrayLessWeekRest.push(faultRecord);
                                            } 
                                      } else {
                                              let zeroDayFrom = new Date(previousRecord.endTime).getTime();
                                              let timeTo = new Date(record.startTime).getTime();
                                              let timeDifference = timeTo - zeroDayFrom

                                              if(timeDifference > 6 * 24 * 60 * 60 * 1000) {
                                                const originalDate = new Date(firstValue.StartTime);
                                                const newDate = new Date(originalDate.getTime() + 6 * 24 * 60 * 60 * 1000);
                                                const dateFrom = originalDate.toISOString();
                                                const formattedDate = formatLocalTime(newDate.toISOString()).split("T")[0];
                                                const formattedTime = formatLocalTime(newDate.toISOString()).split("T")[1].slice(0, -8);
                                                count++
                                                let faultRecord = {
                                                  Prekršaj: count,
                                                  Vozač: record.driver,
                                                  Kompanija: company.Name,
                                                  Poruka: `Kasno započet nedelji odmor! Poslednji odmor je ${formatSRBTime(dateFrom)}, a počeo naredni ${formatSRBTime(record.startTime)}. Trebao je početi naredni dana ${formatSRBTime(formattedDate)}, vreme ${formattedTime}`,
                                                };
                                                arrayLessWeekRest.push(faultRecord);
                                              }
                                      } 
                                        //DUPLI KOD
                                      // if(start === 0) {
                                      //   start = currentCompare33h.endTime;
                                      // } else {                
                                      //   if(previousRecord) {
                                      //     const hours = parseInt(previousRecord.SumTotalWeekRestTime.split(":")[0], 10);
                                      //     if(hours >= 33){
                                      //       currentCompare33h = previousRecord
                                      //     }
                                      //   }
                                      // }
                                  } else {
                                            if (record.startTime != currentCompare33h.startTime) {           
                                                      const beginTime = new Date(start).getTime();
                                                      const endTime = new Date(record.startTime).getTime();
                                                      const timeDifference = endTime - beginTime;                                                                                         
                                                      if (timeDifference > 6 * 24 * 60 * 60 * 1000) {
                                                        const originalDate = new Date(start);
                                                        const dateFrom = originalDate.toISOString();
                                                        const newDate = new Date(originalDate.getTime() + 6 * 24 * 60 * 60 * 1000);
                                                        const formattedDate = formatLocalTime(newDate.toISOString()).split("T")[0];
                                                        const formattedTime = formatLocalTime(newDate.toISOString()).split("T")[1].slice(0, -8);
                                                        count++
                                                        let faultRecord = {
                                                          Prekršaj: count,
                                                            Vozač: record.driver,
                                                            Kompanija: company.Name,
                                                            Poruka: `Kasno započet nedelji odmor! Poslednji odmor je ${formatSRBTime(dateFrom)}, a počeo naredni ${formatSRBTime(record.startTime)}. Trebao je početi naredni dana ${formatSRBTime(formattedDate)}, vreme ${formattedTime}`,
                                                          };
                                                        
                                                        arrayLessWeekRest.push(faultRecord);
                                                        start = record.endTime;
                                                        currentCompare33h = record;
                                                      } else { 
                                                        start = record.endTime;
                                                        currentCompare33h = record;
                                                      }
                                          
                                            } else {
                                              //dupli kod, ima provere u prvoj grani, ako nema previous record, a prvi put se javlja 33h
                                                      const beginTime = new Date(firstValue.StartTime).getTime();
                                                      const endTime = new Date(record.startTime).getTime();
                                                      const timeDifference = endTime - beginTime;
                                    
                                                      if (timeDifference > 6 * 24 * 60 * 60 * 1000) {
                                                        const originalDate = new Date(firstValue.StartTime);
                                                        const newDate = new Date(originalDate.getTime() + 6 * 24 * 60 * 60 * 1000);
                                                        const formattedDate = formatLocalTime(newDate.toISOString()).split("T")[0];
                                                        const formattedTime = formatLocalTime(newDate.toISOString()).split("T")[1].slice(0, -8);
                                                        count++
                                                          let faultRecord = {
                                                            Prekršaj: count,
                                                            Vozač: record.driver,
                                                            Kompanija: company.Name,
                                                            Poruka: `Kasno započet nedelji odmor! Poslednji odmor je ${formatSRBTime(originalDate.toISOString())}, a počeo naredni ${formatSRBTime(record.startTime)}. Trebao je početi naredni dana ${formatSRBTime(formattedDate)}, vreme ${formattedTime}`,
                                                          };
                                                        arrayLessWeekRest.push(faultRecord);
                                                      } 
                                            }
                                  }
                          } else {
                            if(currentCompare33h == 0) {
                                        if(!nextRecord){
                                          let zeroDayFrom = new Date(firstValue.StartTime).getTime();
                                          let timeTo = new Date(record.startTime).getTime();
                                          let timeDifference = timeTo - zeroDayFrom

                                              if(timeDifference > 6 * 24 * 60 * 60 * 1000) {
                                                
                                                const newDate = new Date(zeroDayFrom + 6 * 24 * 60 * 60 * 1000);
                                                const dateFromToStr = newDate.toISOString();
                                                const formattedDate = formatLocalTime(newDate.toISOString()).split("T")[0];
                                                const formattedTime = formatLocalTime(newDate.toISOString()).split("T")[1].slice(0, -8);
                                                count++
                                                let faultRecord = {
                                                  Prekršaj: count,
                                                  Vozač: record.driver,
                                                  Kompanija: company.Name,
                                                  Poruka: `Kasno započet nedelji odmor! Poslednji odmor je ${formatSRBTime(dateFromToStr)}, a počeo naredni ${formatSRBTime(record.startTime)}. Trebao je početi naredni dana ${formatSRBTime(formattedDate)}, vreme ${formattedTime}`,
                                                };
                                                arrayLessWeekRest.push(faultRecord);
                                              } else {
                                                const newDate = new Date(zeroDayFrom);
                                                const dateFromToStr = newDate.toISOString();
                                                count++
                                                let faultRecord = {
                                                  Prekršaj: count,
                                                  Vozač: record.driver,
                                                  Kompanija: company.Name,
                                                  Poruka: `Nema dovoljni nedeljni odmor dana ${formatSRBTime(dateFromToStr)}! Odmor je trajao samo ${record.SumTotalWeekRestTime}`,
                                                };
                                                arrayLessWeekRest.push(faultRecord);
                                              } //////ovde nastaviti
                                            
                                        
                                        } else {
                                          const lastRestFinish = new Date(firstValue.StartTime);
                                          const lastNewDate = lastRestFinish.getTime() + 6 * 24 * 60 * 60 * 1000;
                                          const nextRecordStart = new Date(nextRecord.startTime);
                                          const NewDatenextRecordStart = nextRecordStart.getTime();
                                          if(NewDatenextRecordStart < lastNewDate) {
                                            continue;
                                          } else {
                                            const restDuration = parseInt(record.SumTotalWeekRestTime.split(":")[0], 10);
                                            if (restDuration < 33) {
                                              count++
                                                let faultRecord = {
                                                  Prekršaj: count,
                                                  Vozač: record.driver,
                                                  Kompanija: company.Name,
                                                  Poruka: `Nema dovoljni nedeljni odmor ${formatSRBTime(record.endTime)}! Odmor je trajao samo ${record.SumTotalWeekRestTime}`,
                                                }; 
                                              
                                              arrayLessWeekRest.push(faultRecord);     
                                              start = record.endTime;
                                              currentCompare33h = record;                                                      
                                            }
                                          }
                                        }
                            } else {
                              if(!nextRecord){
                                let zeroDayFrom = new Date(start).getTime();
                                let timeTo = new Date(record.startTime).getTime();
                                let timeDifference = timeTo - zeroDayFrom

                                    if(timeDifference > 6 * 24 * 60 * 60 * 1000) {
                                      
                                      const newDate = new Date(zeroDayFrom + 6 * 24 * 60 * 60 * 1000);
                                      const formattedDate = formatLocalTime(newDate.toISOString()).split("T")[0];
                                      const formattedTime = formatLocalTime(newDate.toISOString()).split("T")[1].slice(0, -8);
                                      count++
                                      let faultRecord = {
                                        Prekršaj: count,
                                        Vozač: record.driver,
                                        Kompanija: company.Name,
                                        Poruka: `Kasno započet nedelji odmor dana ${formatSRBTime(record.startTime)}! Trebao je početi dana ${formatSRBTime(formattedDate)}, vreme ${formattedTime}`,
                                      };
                                      arrayLessWeekRest.push(faultRecord);
                                    } else {
                                      let zeroDayFrom = new Date(start).getTime();
                                      let timeTo = new Date(record.endTime).getTime();
                                      let timeDifference = timeTo - zeroDayFrom;
                                      const newDate = new Date(zeroDayFrom + 6 * 24 * 60 * 60 * 1000);
                                      const formattedDate = newDate.toISOString().split("T")[0];
                                      const formattedTime = newDate.toISOString().split("T")[1].slice(0, -8);
                                    
                                      if(timeDifference < 6 * 24 * 60 * 60 * 1000) {
                                        
                                          let lastElement = filtered[filtered.length - 1];
                                          const lastRestFinish = new Date(start);
                                          const lastNewDate = lastRestFinish.getTime() + 6 * 24 * 60 * 60 * 1000;
                                          const nextRecordStart = new Date(lastElement.endTime);
                                          const NewDatenextRecordStart = nextRecordStart.getTime();
                                          if(NewDatenextRecordStart < lastNewDate) {
                                            continue;
                                          } else {
                                            count++
                                              let faultRecord = {
                                              Prekršaj: count,
                                              Vozač: record.driver,
                                              Kompanija: company.Name,
                                              Poruka: `Nema dovoljni nedeljni odmor dana ${formatSRBTime(record.endTime)}! Odmor je trajao samo ${record.SumTotalWeekRestTime}.`
                                            };
                                  
                                          arrayLessWeekRest.push(faultRecord);
                                          start = record.endTime;     
                                          }
                                      } else {
                                    
                                        count++
                                          let faultRecord = {
                                            Prekršaj: count,
                                            Vozač: record.driver,
                                            Kompanija: company.Name,
                                            Poruka: `Nema nedeljni odmor. Poslednji odmor je ${formatSRBTime(new Date(zeroDayFrom).toISOString())}! Trebao je početi dana ${formatSRBTime(formattedDate)}, vreme ${formattedTime}`,
                                        };
                                        arrayLessWeekRest.push(faultRecord);
                                      }
                                      
                                    }
                              } else {
                                const lastRestFinish = new Date(start);
                                const lastNewDate = lastRestFinish.getTime() + 6 * 24 * 60 * 60 * 1000;
                                const nextRecordStart = new Date(nextRecord.startTime);
                                const NewDatenextRecordStart = nextRecordStart.getTime();
                                if(NewDatenextRecordStart < lastNewDate) {
                                  continue;
                                } else {
                                  if(previousRecord){
                                    if(start !== previousRecord.endTime) {
                                      const restDurationPrevious = parseInt(previousRecord.SumTotalWeekRestTime.split(":")[0], 10);
                                      const restDuration = parseInt(record.SumTotalWeekRestTime.split(":")[0], 10);
                                      let nextDuration = 0;
                                      if(nextRecord){
                                        nextDuration = parseInt(nextRecord.SumTotalWeekRestTime.split(":")[0], 10);
                                        if(restDurationPrevious > restDuration){
                                          if(nextDuration >= 33) {
                                            const lastRestFinish = new Date(previousRecord.endTime);
                                            const lastNewDate = lastRestFinish.getTime() + 6 * 24 * 60 * 60 * 1000;
                                            const nextRecordStart = new Date(nextRecord.startTime);
                                            const NewDatenextRecordStart = nextRecordStart.getTime();
                                            if(NewDatenextRecordStart < lastNewDate) {
                                              if (restDurationPrevious < 33 && restDurationPrevious >= 29) {
                                                count++
                                                let faultRecord = {
                                                  Prekršaj: count,
                                                  Vozač: previousRecord.driver,
                                                  Kompanija: company.Name,
                                                  Poruka: `Nema dovoljni nedeljni odmor dana ${formatSRBTime(previousRecord.endTime)}! Odmor je trajao samo ${previousRecord.SumTotalWeekRestTime}.`
                                                };
                                      
                                              arrayLessWeekRest.push(faultRecord);
                                              start = previousRecord.endTime;                                                         
                                        } 
                                            } else {
                                              if (restDuration < 33 && restDuration >= 29) {
                                                count++
                                                let faultRecord = {
                                                  Prekršaj: count,
                                                  Vozač: record.driver,
                                                  Kompanija: company.Name,
                                                  Poruka: `Nema dovoljni nedeljni odmor dana ${formatSRBTime(record.endTime)}! Odmor je trajao samo ${record.SumTotalWeekRestTime}.`
                                                };
                                      
                                              arrayLessWeekRest.push(faultRecord);
                                              start = record.endTime;                                                         
                                        } else if (restDuration < 29) {
                                        
                                            const newDate = new Date(start).getTime() + 6 * 24 * 60 * 60 * 1000;
                                            const dateFromToStr = new Date(newDate).toISOString();
                                            const formattedDate = formatLocalTime(dateFromToStr).split("T")[0];
                                            const formattedTime = formatLocalTime(dateFromToStr).split("T")[1].slice(0, -8);
                                            const formattedDate2 = formatLocalTime(start).split("T")[0];
                                            const formattedTime2 = formatLocalTime(start).split("T")[1].slice(0, -8);

                                            count++
                                                let faultRecord = {
                                                  Prekršaj: count,
                                                  Vozač: record.driver,
                                                  Kompanija: company.Name,
                                                  Poruka: `Nema nedeljni odmor dana ${formatSRBTime(record.endTime)}! Poslednji odmor je ${formatSRBTime(formattedDate2)} - ${formattedTime2}!  Trebao je početi do dana ${formatSRBTime(formattedDate)} vreme ${formattedTime}.`
                                                };
                                      
                                              arrayLessWeekRest.push(faultRecord);
                                              start = dateFromToStr;  
                                        }
                                            }
                                          } else {
                                                    if (restDuration < 33 && restDuration >= 29) {
                                                      let faultRecord = {
                                                        Vozač: record.driver,
                                                        Kompanija: company.Name,
                                                        Poruka: `Nema dovoljni nedeljni odmor dana ${formatSRBTime(record.endTime)}! Odmor je trajao samo ${record.SumTotalWeekRestTime}.`
                                                      };
                                            
                                                    arrayLessWeekRest.push(faultRecord);
                                                    start = record.endTime;                                                         
                                              } else if (restDuration < 29) {
                                              
                                                  const newDate = new Date(start).getTime() + 6 * 24 * 60 * 60 * 1000;
                                                  const dateFromToStr = new Date(newDate).toISOString();
                                                  const formattedDate = formatLocalTime(dateFromToStr).split("T")[0];
                                                  const formattedTime = formatLocalTime(dateFromToStr).split("T")[1].slice(0, -8);
                                                  const formattedDate2 = formatLocalTime(start).split("T")[0];
                                                  const formattedTime2 = formatLocalTime(start).split("T")[1].slice(0, -8);

                                                  count++
                                                      let faultRecord = {
                                                        Prekršaj: count,
                                                        Vozač: record.driver,
                                                        Kompanija: company.Name,
                                                        Poruka: `Nema nedeljni odmor dana ${formatSRBTime(record.endTime)}! Poslednji odmor je ${formatSRBTime(formattedDate2)} - ${formattedTime2}!  Trebao je početi do dana ${formatSRBTime(formattedDate)} vreme ${formattedTime}.`
                                                      };
                                            
                                                    arrayLessWeekRest.push(faultRecord);
                                                    start = dateFromToStr;  
                                              }
                                          }
                                        } else {
                                                  if (restDuration < 33 && restDuration >= 29) {
                                                    count++
                                                    let faultRecord = {
                                                      Prekršaj: count,
                                                      Vozač: record.driver,
                                                      Kompanija: company.Name,
                                                      Poruka: `Nema dovoljni nedeljni odmor dana ${formatSRBTime(record.endTime)}! Odmor je trajao samo ${record.SumTotalWeekRestTime}.`
                                                    };
                                          
                                                  arrayLessWeekRest.push(faultRecord);
                                                  start = record.endTime;                                                         
                                            } else if (restDuration < 29) {
                                            
                                                const newDate = new Date(start).getTime() + 6 * 24 * 60 * 60 * 1000;
                                                const dateFromToStr = new Date(newDate).toISOString();
                                                const formattedDate = formatLocalTime(dateFromToStr).split("T")[0];
                                                const formattedTime = formatLocalTime(dateFromToStr).split("T")[1].slice(0, -8);
                                                const formattedDate2 = formatLocalTime(start).split("T")[0];
                                                const formattedTime2 = formatLocalTime(start).split("T")[1].slice(0, -8);

                                                count++
                                                    let faultRecord = {
                                                      Prekršaj: count,
                                                      Vozač: record.driver,
                                                      Kompanija: company.Name,
                                                      Poruka: `Nema nedeljni odmor dana ${formatSRBTime(record.endTime)}! Poslednji odmor je ${formatSRBTime(formattedDate2)} - ${formattedTime2}!  Trebao je početi do dana ${formatSRBTime(formattedDate)} vreme ${formattedTime}.`
                                                    };
                                          
                                                  arrayLessWeekRest.push(faultRecord);
                                                  start = dateFromToStr;  
                                            }
                                        }
                                      } else {
                                                  if (restDuration < 33 && restDuration >= 29) {
                                                    count++
                                                    let faultRecord = {
                                                      Prekršaj: count,
                                                      Vozač: record.driver,
                                                      Kompanija: company.Name,
                                                      Poruka: `Nema dovoljni nedeljni odmor dana ${formatSRBTime(record.endTime)}! Odmor je trajao samo ${record.SumTotalWeekRestTime}.`
                                                    };
                                          
                                                  arrayLessWeekRest.push(faultRecord);
                                                  start = record.endTime;                                                         
                                            } else if (restDuration < 29) {
                                            
                                                const newDate = new Date(start).getTime() + 6 * 24 * 60 * 60 * 1000;
                                                const dateFromToStr = new Date(newDate).toISOString();
                                                const formattedDate = formatLocalTime(dateFromToStr).split("T")[0];
                                                const formattedTime = formatLocalTime(dateFromToStr).split("T")[1].slice(0, -8);
                                                const formattedDate2 = formatLocalTime(start).split("T")[0];
                                                const formattedTime2 = formatLocalTime(start).split("T")[1].slice(0, -8);

                                                count++
                                                    let faultRecord = {
                                                      Prekršaj: count,
                                                      Vozač: record.driver,
                                                      Kompanija: company.Name,
                                                      Poruka: `Nema nedeljni odmor dana ${formatSRBTime(record.endTime)}! Poslednji odmor je ${formatSRBTime(formattedDate2)} - ${formattedTime2}!  Trebao je početi do dana ${formatSRBTime(formattedDate)} vreme ${formattedTime}.`
                                                    };
                                          
                                                  arrayLessWeekRest.push(faultRecord);
                                                  start = dateFromToStr;  
                                            }
                                      }
                                    
                                    } else {
                                      if(nextRecord) {
                                          const start33 = new Date(start).getTime()
                                          const next33 = new Date(nextRecord.startTime).getTime()
                                          let difference = next33 - start33
                                          if(difference > 6 * 24 * 60 * 60 * 1000) {
                                            count++
                                              let faultRecord = {
                                                Prekršaj: count,
                                              Vozač: record.driver,
                                              Kompanija: company.Name,
                                              Poruka: `Nema dovoljni nedeljni odmor dana ${formatSRBTime(record.endTime)}! Odmor je trajao samo ${record.SumTotalWeekRestTime}.`
                                            };
                                  
                                          arrayLessWeekRest.push(faultRecord);
                                          start = record.endTime;         
                                          }
                                        
                                        // else {

                                        //  }
                                      }
                                    }

                                  } else {
                                    const restDuration = parseInt(record.SumTotalWeekRestTime.split(":")[0], 10);

                                  if (restDuration < 33 && restDuration >= 29) {
                                    count++
                                          let faultRecord = {
                                            Prekršaj: count,
                                            Vozač: record.driver,
                                            Kompanija: company.Name,
                                            Poruka: `Nema dovoljni nedeljni odmor dana ${formatSRBTime(record.endTime)}! Odmor je trajao samo ${record.SumTotalWeekRestTime}.`
                                          };
                                
                                        arrayLessWeekRest.push(faultRecord);
                                        start = record.endTime;                                                         
                                  } else if (restDuration < 29) {
                                  
                                      const newDate = new Date(start).getTime() + 6 * 24 * 60 * 60 * 1000;
                                      const dateFromToStr = new Date(newDate).toISOString();
                                      const formattedDate = formatLocalTime(dateFromToStr).split("T")[0];
                                      const formattedTime = formatLocalTime(dateFromToStr).split("T")[1].slice(0, -8);
                                      const formattedDate2 = formatLocalTime(start).split("T")[0];
                                      const formattedTime2 = formatLocalTime(start).split("T")[1].slice(0, -8);

                                      count++
                                          let faultRecord = {
                                            Prekršaj: count,
                                            Vozač: record.driver,
                                            Kompanija: company.Name,
                                            Poruka: `Nema nedeljni odmor dana ${formatSRBTime(record.endTime)}! Poslednji odmor je ${formatSRBTime(formattedDate2)} - ${formattedTime2}!  Trebao je početi do dana ${formatSRBTime(formattedDate)} vreme ${formattedTime}.`
                                          };
                                
                                        arrayLessWeekRest.push(faultRecord);
                                        start = dateFromToStr;  
                                  }
                                  }
                                  
                                }
                              }
                          }
                        }
                    }
                  } 
                  else {
                    let start24 = 0;
                    let current24h = 0;

                    let start45 = 0;
                    let current45h = 0;
                    let timeBeforeSecond45 = 12 * 24 * 60 * 60 * 1000 + 3 * 60  * 60 * 1000; // 12 dana + 3h pre pocetka naredne 45h pauze

                    for (let i = 0; i < arrayPossibleWeekRest.length; i++) {
                      const record = arrayPossibleWeekRest[i];
                      const previousRecord = arrayPossibleWeekRest[i-1];
                      const nextRecord = arrayPossibleWeekRest[i+1];
                      const [hours, minutes] = record.SumTotalWeekRestTime.split(":").map(Number);
                      const SumTotalWeekRestTimeInMS = (hours * 60 + minutes) * 60 * 1000; 

                        if (SumTotalWeekRestTimeInMS >= 45 * 60 * 60 * 1000) {
                                //current24h = 0
                                if(current45h == 0) {
                                  start45 = record.endTime;
                                  current45h = record;
                                    if(!previousRecord) {
                                    
                                          let zeroDayFrom = new Date(firstValue.StartTime).getTime();
                                          let timeTo = new Date(record.startTime).getTime();
                                          let timeDifference = timeTo - zeroDayFrom;

                                          if(timeDifference > 6 * 24 * 60 * 60 * 1000) {
                                            
                                            const newDate = new Date(zeroDayFrom + 6 * 24 * 60 * 60 * 1000);
                                            const newDateSeconds = new Date(zeroDayFrom + 6 * 24 * 60 * 60 * 1000).getTime();
                                            const timeElapsed = newDateSeconds - zeroDayFrom;
                                            const formattedDate = formatLocalTime(newDate.toISOString()).split("T")[0];
                                            const formattedTime = formatLocalTime(newDate.toISOString()).split("T")[1].slice(0, -8);
                                            count++
                                              let faultRecord = {
                                              Prekršaj: count,
                                              Vozač: record.driver,
                                              Kompanija: company.Name,
                                              Poruka: `Kasno započet nedelji odmor dana ${formatSRBTime(record.startTime)}! Trebao je početi dana ${formatSRBTime(formattedDate)}, vreme ${formattedTime}. Kasnio započeo za ${formatTime(timeElapsed)}!`,
                                            };
                                            arrayLessWeekRest.push(faultRecord);
                                          }
                                    } else {
                                            if(current24h == 0) {
                                              let zeroDayFrom = new Date(firstValue.StartTime).getTime();
                                              let timeTo = new Date(record.startTime).getTime();
                                              let timeDifference = timeTo - zeroDayFrom
                                              if(timeDifference > 6 * 24 * 60 * 60 * 1000) {
                                                const originalDate = new Date(firstValue.StartTime);
                                                const newDate = new Date(originalDate.getTime() + 6 * 24 * 60 * 60 * 1000);
                                                const timeElapsed = new Date(record.startTime).getTime() - new Date(newDate).getTime();
                                                const formattedDate = formatLocalTime(newDate.toISOString()).split("T")[0];
                                                const formattedTime = formatLocalTime(newDate.toISOString()).split("T")[1].slice(0, -8);
                                                count++
                                                let faultRecord = {
                                                  Prekršaj: count,
                                                  Vozač: record.driver,
                                                  Kompanija: company.Name,
                                                  Poruka: `Kasno započet nedelji odmor dana ${formatSRBTime(record.startTime)}! Trebao je početi dana ${formatSRBTime(formattedDate)}, vreme ${formattedTime}. Kasnio započeo za ${formatTime(timeElapsed)}!`,
                                                };
                                                arrayLessWeekRest.push(faultRecord);
                                              }
                                            } else {
                                                  let zeroDayFrom = new Date(start24).getTime();
                                                  let timeTo = new Date(record.startTime).getTime();
                                                  let timeDifference = timeTo - zeroDayFrom
                                                  if(timeDifference > 6 * 24 * 60 * 60 * 1000) {
                                                    const originalDate = new Date(start24);
                                                    const newDate = new Date(originalDate.getTime() + 6 * 24 * 60 * 60 * 1000);
                                                    const timeElapsed = new Date(record.startTime).getTime() - new Date(newDate).getTime();
                                                    const formattedDate = formatLocalTime(newDate.toISOString()).split("T")[0];
                                                    const formattedTime = formatLocalTime(newDate.toISOString()).split("T")[1].slice(0, -8);
                                                    count++
                                                    let faultRecord = {
                                                      Prekršaj: count,
                                                      Vozač: record.driver,
                                                      Kompanija: company.Name,
                                                      Poruka: `Kasno započet nedelji odmor od 45h dana ${formatSRBTime(record.startTime)}! Trebao je početi dana ${formatSRBTime(formattedDate)}, vreme ${formattedTime}. Kasnio započeo za ${formatTime(timeElapsed)}!`,
                                                    };
                                                    arrayLessWeekRest.push(faultRecord);
                                                    start24 = 0;
                                                    current24h = 0;
                                                  } else {
                                                    let zeroDayFrom = new Date(firstValue.StartTime).getTime();
                                                    const now45 = new Date(record.startTime).getTime();
                                                    let elapesdTillSecond45 = now45 - zeroDayFrom;
                                                      if(elapesdTillSecond45 > timeBeforeSecond45) {
                                                          const newDate = new Date(zeroDayFrom + 12 * 24 * 60 * 60 * 1000 + 3 * 60 * 60 * 1000);
                                                          const timeElapsed = new Date(record.startTime).getTime() - new Date(newDate).getTime();
                                                          const formattedDate = formatLocalTime(newDate.toISOString()).split("T")[0];
                                                          const formattedTime = formatLocalTime(newDate.toISOString()).split("T")[1].slice(0, -8);
                                                          count++
                                                            let faultRecord = {
                                                              Prekršaj: count,
                                                              Vozač: record.driver,
                                                              Kompanija: company.Name,
                                                              Poruka: `Kasno započet PUN nedeljni odmor dana ${formatSRBTime(record.startTime)}! Trebao je početi dana ${formatSRBTime(formattedDate)}, vreme ${formattedTime}. Kasnio započeo za ${formatTime(timeElapsed)}!`,
                                                            };
                                                          
                                                          arrayLessWeekRest.push(faultRecord);
                                                          start24 = 0;
                                                          current24h = 0;
                                                    } else {
                                                      start24 = 0;
                                                      current24h = 0;
                                                    }
                                                  }
                                            }
                                          }
                                } else {
                                        if (record.startTime != current45h.startTime) {   
                                          const last45 = new Date(start45).getTime();
                                          const now45 = new Date(record.startTime).getTime();
                                          let elapesdTillSecond45 = now45 - last45;
                                                if(elapesdTillSecond45 > timeBeforeSecond45) {  
                                                          
                                                  const originalDate = new Date(last45);
                                                  const newDate = new Date(originalDate.getTime() + 12 * 24 * 60 * 60 * 1000 + 3 * 60 * 60 * 1000);
                                                  const timeElapsed = new Date(record.startTime).getTime() - new Date(newDate).getTime();
                                                  const formattedDate = formatLocalTime(newDate.toISOString()).split("T")[0];
                                                  const formattedTime = formatLocalTime(newDate.toISOString()).split("T")[1].slice(0, -8);
                                                  count++
                                                          let faultRecord = {
                                                            Prekršaj: count,
                                                            Vozač: record.driver,
                                                            Kompanija: company.Name,
                                                            Poruka: `Kasno započet PUN nedeljni odmor dana ${formatSRBTime(record.startTime)}! Trebao je početi dana ${formatSRBTime(formattedDate)}, vreme ${formattedTime}. Kasnio započeo za ${formatTime(timeElapsed)}!`,
                                                          };
                                                  arrayLessWeekRest.push(faultRecord);
                                                  start45 = record.endTime;
                                                  current45h = record;
                                                  current24h = 0
                                                } else {
                                                      if(current24h == 0){
                                                        let zeroDayFrom = new Date(start45).getTime();
                                                        let timeTo = new Date(record.startTime).getTime();
                                                        let timeDifference = timeTo - zeroDayFrom;
                                                        if(timeDifference > 6 * 24 * 60 * 60 * 1000){
                                                          const newDate = new Date(zeroDayFrom + 6 * 24 * 60 * 60 * 1000);
                                                          const timeElapsed = new Date(record.startTime).getTime() - new Date(newDate).getTime();
                                                          const formattedDate = formatLocalTime(newDate.toISOString()).split("T")[0];
                                                          const formattedTime = formatLocalTime(newDate.toISOString()).split("T")[1].slice(0, -8);
                                                          count++
                                                                    let faultRecord = {
                                                                      Prekršaj: count,
                                                                      Vozač: record.driver,
                                                                      Kompanija: company.Name,
                                                                      Poruka: `Kasno započet nedeljni odmor dana ${formatSRBTime(record.startTime)}! Trebao je početi dana ${formatSRBTime(formattedDate)}, vreme ${formattedTime}. Kasnio započeo za ${formatTime(timeElapsed)}!`,
                                                                    };
                                                            arrayLessWeekRest.push(faultRecord);
                                                            start45 = record.endTime;
                                                            current45h = record;
                                                            }else{
                                                            start45 = record.endTime;
                                                            current45h = record;
                                                          } 
                                                      } else {
                                                        let zeroDayFrom = new Date(start24).getTime();
                                                        let timeTo = new Date(record.startTime).getTime();
                                                        let timeDifference = timeTo - zeroDayFrom
                                                        if(timeDifference > 6 * 24 * 60 * 60 * 1000){
                                                          const originalDate = new Date(start24);
                                                            const newDate = new Date(originalDate.getTime() + 6 * 24 * 60 * 60 * 1000);
                                                            const timeElapsed = new Date(record.startTime).getTime() - new Date(newDate).getTime();
                                                            const formattedDate = formatLocalTime(newDate.toISOString()).split("T")[0];
                                                            const formattedTime = formatLocalTime(newDate.toISOString()).split("T")[1].slice(0, -8);
                                                            count++
                                                                    let faultRecord = {
                                                                      Prekršaj: count,
                                                                      Vozač: record.driver,
                                                                      Kompanija: company.Name,
                                                                      Poruka: `Kasno započet nedeljni odmor dana ${formatSRBTime(record.startTime)}! Trebao je početi dana ${formatSRBTime(formattedDate)}, vreme ${formattedTime}. Kasnio započeo za ${formatTime(timeElapsed)}!`,
                                                                    };
                                                            arrayLessWeekRest.push(faultRecord);
                                                            start45 = record.endTime;
                                                            current45h = record;
                                                            start24 = 0;
                                                            current24h = 0;
                                                        } else {
                                                          start45 = record.endTime;
                                                          current45h = record;
                                                          start24 = 0;
                                                          current24h = 0;
                                                        }
                                                      }
                                                    }
                                          }                                                   
                                }
                        } else if (SumTotalWeekRestTimeInMS >= 24 * 60 * 60 * 1000 && SumTotalWeekRestTimeInMS < 45 * 60 * 60 * 1000) {
                          if(current45h == 0 && current24h == 0) {
                                      if(!nextRecord){
                                        let zeroDayFrom = new Date(firstValue.StartTime).getTime();
                                        let timeTo = new Date(record.startTime).getTime();
                                        let timeDifference = timeTo - zeroDayFrom

                                            if(timeDifference > 6 * 24 * 60 * 60 * 1000) {
                                                    
                                                    const newDate = new Date(zeroDayFrom + 6 * 24 * 60 * 60 * 1000);
                                                    const timeElapsed = new Date(record.startTime).getTime() - new Date(newDate).getTime();
                                                    const formattedDate = formatLocalTime(newDate.toISOString()).split("T")[0];
                                                    const formattedTime = formatLocalTime(newDate.toISOString()).split("T")[1].slice(0, -8);
                                                    count++
                                                    let faultRecord = {
                                                      Prekršaj: count,
                                                      Vozač: record.driver,
                                                      Kompanija: company.Name,
                                                      Poruka: `Kasno započet nedelji odmor dana ${formatSRBTime(record.startTime)}! Trebao je početi dana ${formatSRBTime(formattedDate)}, vreme ${formattedTime}. Kasnio započeo za ${formatTime(timeElapsed)}!`,
                                                    };
                                                    arrayLessWeekRest.push(faultRecord);
                                                    start24 = record.endTime;
                                                    current24h = record;

                                            } else {
                                              let dateToPicked = new Date(dateTo).getTime();
                                              let timeEnd = new Date(record.endTime).getTime();
                                              let difference = dateToPicked - timeEnd;
                                              if(difference > 6 * 24 * 60 * 60 * 1000){
                                                const newDate = new Date(timeEnd + 6 * 24 * 60 * 60 * 1000);
                                                const timeElapsed = dateToPicked - new Date(newDate).getTime();
                                                count++
                                                let faultRecord = {
                                                  Prekršaj: count,
                                                  Vozač: record.driver,
                                                  Kompanija: company.Name,
                                                  Poruka: `Kasno započet nedelji odmor dana ${formatSRBTime(dateToPicked)}! Kasnio najmanje ${formatTime(timeElapsed)}. Nema odmora većeg od 24h do DATUM DO izabranog za izveštaj. Ili nema aktivnosti ili nema vikend odmora!`,
                                                };
                                                arrayLessWeekRest.push(faultRecord);
                                              }
                                            }
                                          
                                      //ODAVNE NASTAVITI SA DODAVANJEM ELAPSED TIME
                                      } else {
                                        const lastRestFinish = new Date(firstValue.StartTime);
                                        const lastNewDate = lastRestFinish.getTime() + 6 * 24 * 60 * 60 * 1000;
                                        const nextRecordStart = new Date(nextRecord.startTime);
                                        const NewDatenextRecordStart = nextRecordStart.getTime();
                                        if(NewDatenextRecordStart < lastNewDate) {
                                          const hours = parseInt(nextRecord.SumTotalWeekRestTime.split(":")[0], 10);
                                          if(hours >= 24) {
                                            continue;
                                          } else {
                                            current24h = record;
                                            start24 = record.endTime;
                                          }
                                          
                                        } else {
                                  
                                            start24 = record.endTime;
                                            current24h = record;                                                      

                                        }
                                      }
                          } else if(current45h != 0 && current24h == 0) {
                            if(!nextRecord){
                              let zeroDayFrom = new Date(start45).getTime();
                              let timeTo = new Date(record.startTime).getTime();
                              let timeDifference = timeTo - zeroDayFrom

                                  if(timeDifference > 6 * 24 * 60 * 60 * 1000) {
                                    
                                    const newDate = new Date(zeroDayFrom + 6 * 24 * 60 * 60 * 1000);
                                    const timeElapsed = new Date(record.startTime).getTime() - new Date(newDate).getTime();
                                    const formattedDate = formatLocalTime(newDate.toISOString()).split("T")[0];
                                    const formattedTime = formatLocalTime(newDate.toISOString()).split("T")[1].slice(0, -8);
                                    count++
                                      let faultRecord = {
                                            Prekršaj: count,
                                            Vozač: record.driver,
                                            Kompanija: company.Name,
                                            Poruka: `Kasno započet nedelji odmor dana ${formatSRBTime(record.startTime)}! Trebao je početi dana ${formatSRBTime(formattedDate)}, vreme ${formattedTime}. Kasnio započeo za ${formatTime(timeElapsed)}!`,
                                          };
                                    arrayLessWeekRest.push(faultRecord);
                                    start24 = record.endTime;
                                    current24h = record;
                                  } else {
                                    start24 = record.endTime;
                                    current24h = record;
                                    continue;
                                  }
                            } else {
                                    const lastRestFinish = new Date(start45);
                                    const lastNewDate = lastRestFinish.getTime() + 6 * 24 * 60 * 60 * 1000;
                                    const currentRecord = new Date(record.startTime);
                                    const newCurrentRecord = currentRecord.getTime();
                            
                                    if(newCurrentRecord > lastNewDate) {
                                              // const lastRestFinish = new Date(start45);
                                              // const newDate = new Date(lastRestFinish.getTime() + 6 * 24 * 60 * 60 * 1000);
                                              // let adjustDate = new Date(newDate.getTime() + 24 * 60 * 60 * 1000);
                                              // const formattedDate = newDate.toISOString().split("T")[0];
                                              // const formattedTime = newDate.toISOString().split("T")[1].slice(0, -8);
                                              // count++
                                              //     let faultRecord = {
                                              //       Prekršaj: count,
                                              //       Vozač: record.driver,
                                              //       Kompanija: company.Name,
                                              //       Poruka: `Kasno započet nedeljni odmor dana ${formattedDate}! Odmor je trebao početi ${formatSRBTime(formattedDate)} od ${formattedTime}`,
                                              //     };
                                              //   arrayLessWeekRest.push(faultRecord); 
                                        
                                              //   current24h = adjustDate.toISOString();
                                              //   start24 = adjustDate.toISOString();
                                              const lastRestFinish = new Date(start45).getTime();
                                              const currentRecord = new Date(record.startTime).getTime();
                                              if((currentRecord - lastRestFinish) > 8 * 24 * 60 * 60 * 1000 + 12 * 60 * 60 * 1000){
                                        
                                                  const originalDate = new Date(start45);
                                                  const newDate = new Date(originalDate.getTime() + 6 * 24 * 60 * 60 * 1000);
                                                  const formattedDate = formatLocalTime(newDate.toISOString()).split("T")[0];
                                                  count++
                                                          let faultRecord = {
                                                            Prekršaj: count,
                                                            Vozač: record.driver,
                                                            Kompanija: company.Name,
                                                            Poruka: `Nema nedeljni odmor dana ${formatSRBTime(formattedDate)}!`,
                                                          };
                                                  arrayLessWeekRest.push(faultRecord);

                                                  let zeroDayFrom = new Date(start45).getTime();
                                                  const now45 = new Date(record.startTime).getTime();
                                                  let elapesdTillSecond45 = now45 - zeroDayFrom;
                                                    if(elapesdTillSecond45 > timeBeforeSecond45) {
                                                        const newDate = new Date(zeroDayFrom + 12 * 24 * 60 * 60 * 1000 + 3 * 60 * 60 * 1000);
                                                        const timeElapsed = new Date(record.startTime).getTime() - new Date(newDate).getTime();
                                                        const formattedDate = formatLocalTime(newDate.toISOString()).split("T")[0];
                                                        const formattedTime = formatLocalTime(newDate.toISOString()).split("T")[1].slice(0, -8);
                                                        count++
                                                          let faultRecord = {
                                                            Prekršaj: count,
                                                            Vozač: record.driver,
                                                            Kompanija: company.Name,
                                                            Poruka: `Kasno započet PUN nedeljni odmor dana ${formatSRBTime(record.startTime)}! Trebao je početi dana ${formatSRBTime(formattedDate)}, vreme ${formattedTime}. Kasnio započeo za ${formatTime(timeElapsed)}!`,
                                                          };
                                                        
                                                        arrayLessWeekRest.push(faultRecord);
                                                        start24 = 0;
                                                        current24h = 0;
                                                  } else {
                                                    const hours45 = parseInt(record.SumTotalWeekRestTime.split(":")[0], 10);
                                                    
                                                    if(hours45 < 45){
                                                      count++
                                                      let faultRecord = {
                                                        Prekršaj: count,
                                                        Vozač: record.driver,
                                                        Kompanija: company.Name,
                                                        Poruka: `Nema puni nedeljni odmor, treba imati 45h dana ${formatSRBTime(record.endTime)}! Odmor je trajao samo ${record.SumTotalWeekRestTime}!`,
                                                      };
                                                    arrayLessWeekRest.push(faultRecord);     
                                                    start24 = 0;
                                                    current24h = 0;  
                                                    start45 = record.endTime;
                                                    current45h = record;                                                      
                                                  
                                                    }
                                                  }
                                              } else {
                                                  const lastRestFinish = new Date(start45).getTime();
                                                  const currentRecord = new Date(record.startTime).getTime();
                                                  if((currentRecord - lastRestFinish) > 6 * 24 * 60 * 60 * 1000 ){
                                            
                                                      const originalDate = new Date(start45);
                                                      const newDate = new Date(originalDate.getTime() + 6 * 24 * 60 * 60 * 1000);
                                                      const timeElapsed = currentRecord - new Date(newDate).getTime();
                                                      const formattedDate = formatLocalTime(newDate.toISOString()).split("T")[0];
                                                      const formattedTime = formatLocalTime(newDate.toISOString()).split("T")[1].slice(0, -8);
                                                      count++
                                                              let faultRecord = {
                                                                Prekršaj: count,
                                                                Vozač: record.driver,
                                                                Kompanija: company.Name,
                                                                Poruka: `Kasno započet nedeljni odmor ${formatSRBTime(record.startTime)}! Trebao je početi dana ${formatSRBTime(formattedDate)}, vreme ${formattedTime}. Kasno započeo za ${formatTime(timeElapsed)}!`,
                                                              };
                                                      arrayLessWeekRest.push(faultRecord);
                                                      start45 = record.endTime;
                                                      current45h = record;
                                                      start24 = 0;
                                                      current24h = 0;
                                                  } else {
                                                    const hours = parseInt(nextRecord.SumTotalWeekRestTime.split(":")[0], 10);
                                                    if(hours >= 24) {
                                                      continue;
                                                    } else {
                                                      current24h = record;
                                                      start24 = record.endTime;
                                                    }
                                                  }
                                              }

                                    } else {
                                      const lastRestFinish = new Date(start45);
                                      const lastNewDate = lastRestFinish.getTime() + 6 * 24 * 60 * 60 * 1000;
                                      const nextRecordStart = new Date(nextRecord.startTime);
                                      const NewDatenextRecordStart = nextRecordStart.getTime();
                                      if(NewDatenextRecordStart < lastNewDate) {
                                        const hours = parseInt(nextRecord.SumTotalWeekRestTime.split(":")[0], 10);
                                        if(hours >= 24) {
                                          continue;
                                        } else {
                                          current24h = record;
                                          start24 = record.endTime;
                                        }
                                      } else {
                                        start24 = record.endTime;   
                                        current24h = record;   
                                      }
                                    }
                            } 
                          } else if (current45h == 0 && current24h != 0) {
                            if(!nextRecord){
                              let zeroDayFrom = new Date(start24).getTime();
                              let timeTo = new Date(record.startTime).getTime();
                              let timeDifference = timeTo - zeroDayFrom

                                  if(timeDifference > 6 * 24 * 60 * 60 * 1000) {
                                    
                                    const newDate = new Date(zeroDayFrom + 5 * 24 * 60 * 60 * 1000);
                                    const timeElapsed = new Date(record.startTime).getTime() - new Date(newDate).getTime();
                                    const formattedDate = formatLocalTime(newDate.toISOString()).split("T")[0];
                                    const formattedTime = formatLocalTime(newDate.toISOString()).split("T")[1].slice(0, -8);
                                    count++
                                      let faultRecord = {
                                          Prekršaj: count,
                                          Vozač: record.driver,
                                          Kompanija: company.Name,
                                          Poruka: `Kasno započet nedelji odmor dana ${formatSRBTime(record.startTime)}! Trebao je početi dana ${formatSRBTime(formattedDate)}, vreme ${formattedTime}. Kasnio započeo za ${formatTime(timeElapsed)}!`,
                                        };
                                    arrayLessWeekRest.push(faultRecord);
                                    current24h = record;
                                    start24 = record.endTime;
                                  } else {
                                    count++
                                    let faultRecord = {
                                      Prekršaj: count,
                                      Vozač: record.driver,
                                      Kompanija: company.Name,
                                      Poruka: `Nema dovoljni nedeljni odmor, potrebna 45h dana ${formatSRBTime(record.endTime)}! Odmor je trajao samo ${record.SumTotalWeekRestTime}.`,
                                    };
                                    arrayLessWeekRest.push(faultRecord);
                                    current24h = record;
                                    start24 = record.endTime;
                                  }//////////////
                            } else {
                              const lastRestFinish = new Date(start24);
                              const lastNewDate = lastRestFinish.getTime() + 6 * 24 * 60 * 60 * 1000;
                              const nextRecordStart = new Date(nextRecord.startTime);
                              const NewDatenextRecordStart = nextRecordStart.getTime();
                              if(NewDatenextRecordStart < lastNewDate) {
                                const hours = parseInt(nextRecord.SumTotalWeekRestTime.split(":")[0], 10);
                                if(hours >= 24) {
                                  continue;
                                } else {
                                  current24h = record;
                                  start24 = record.endTime;
                                }
                              } else {
                                count++
                                    let faultRecord = {
                                      Prekršaj: count,
                                      Vozač: record.driver,
                                      Kompanija: company.Name,
                                      Poruka: `Nema dovoljni nedeljni odmor, treba 45h dana ${formatSRBTime(record.endTime)}! Odmor je trajao samo ${record.SumTotalWeekRestTime}.`,
                                    };
                                  
                                  arrayLessWeekRest.push(faultRecord);     
                                  start45 = record.endTime;
                                  current45h = record;
                                  start24 = 0;
                                  current24h = 0;                                                
                                }
                              
                            }
                          } else if (current45h != 0 && current24h != 0) { 
                            if(!nextRecord){
                                      
                                      const last24 = new Date(record.endTime).getTime();
                                      const lastValueCheck = new Date(lastValue.EndTime).getTime();
                                      let elapesdTillSecondTillLastValue = lastValueCheck - last24;

                                      if(elapesdTillSecondTillLastValue > 6 * 24 * 60 * 60 * 1000) {
                                        count++
                                        let faultRecord = {
                                          Prekršaj: count,
                                          Vozač: record.driver,
                                          Kompanija: company.Name,
                                          Poruka: `Ima više od 6 x 24h perioda od poslednjeg odmora ${formatSRBTime(record.endTime)} do poslednje aktivnost koje vozač ima a to je ${formatSRBTime(lastValue.EndTime)}!`,
                                        };
                                        arrayLessWeekRest.push(faultRecord);
                                        current24h = 0;
                                        start24 = 0;
                                        current45h = 0;
                                        start45 = 0;
                                      }


                                      const last45 = new Date(start45).getTime();
                                      const now = new Date(record.startTime).getTime();
                                      let elapesdTillSecond45 = now - last45;
                                      if(elapesdTillSecond45 > timeBeforeSecond45) {  
                                        const originalDate = new Date(last45);
                                        const newDate = new Date(originalDate.getTime() + 12 * 24 * 60 * 60 * 1000 + 3 * 60 * 60 * 1000);
                                        const timeElapsed = new Date(record.startTime).getTime() - new Date(newDate).getTime();
                                        const formattedDate = formatLocalTime(newDate.toISOString()).split("T")[0];
                                        const formattedTime = formatLocalTime(newDate.toISOString()).split("T")[1].slice(0, -8);
                                        count++
                                                let faultRecord = {
                                                  Prekršaj: count,
                                                  Vozač: record.driver,
                                                  Kompanija: company.Name,
                                                  Poruka: `Kasno započet DRUGI PUNI nedeljni odmor dana ${formatSRBTime(record.startTime)}! Trebao je početi dana ${formatSRBTime(formattedDate)}, vreme ${formattedTime}. Kasnio započeo za ${formatTime(timeElapsed)}!`,
                                                };
                                        arrayLessWeekRest.push(faultRecord);
                                        start24 = record.endTime;
                                        current24h = record;
                                      } else {
                                        let zeroDayFrom = new Date(start24).getTime();
                                        let timeTo = new Date(record.startTime).getTime();
                                        let timeDifference = timeTo - zeroDayFrom
                                        if(timeDifference < 6 * 24 * 60 * 60 * 1000){
                                
                                          current24h = record;
                                          start24 = record.endTime;

                                        } else {
                                              let zeroDayFrom = new Date(start24).getTime();
                                       
                                              const newDate = new Date(zeroDayFrom + 6 * 24 * 60 * 60 * 1000);
                                              const timeElapsed = new Date(record.startTime).getTime() - new Date(newDate).getTime();
                                              const formattedDate = formatLocalTime(newDate.toISOString()).split("T")[0];
                                              const formattedTime = formatLocalTime(newDate.toISOString()).split("T")[1].slice(0, -8);
                                              count++
                                              let faultRecord = {
                                                Prekršaj: count,
                                                Vozač: record.driver,
                                                Kompanija: company.Name,
                                                Poruka: `Kasno započet nedelji odmor dana ${formatSRBTime(record.startTime)}! Trebao je početi dana ${formatSRBTime(formattedDate)}, vreme ${formattedTime}. Kasnio započeo za ${formatTime(timeElapsed)}!`,
                                              };
                                              arrayLessWeekRest.push(faultRecord);
                                              current45h = record;
                                              start45 = record.endTime;
                                              current24h = 0;
                                              start24 = 0;
                                          }
                              }                                    
                            } else {
                              let zeroDayFrom = new Date(start24).getTime();
                              let timeTo = new Date(nextRecord.startTime).getTime();
                              let timeDifference = timeTo - zeroDayFrom
                              if(timeDifference <= 6 * 24 * 60 * 60 * 1000){
                                const hours = parseInt(nextRecord.SumTotalWeekRestTime.split(":")[0], 10);
                                      if(hours >= 24) {
                                        continue;
                                      } else {
                                        current24h = record;
                                        start24 = record.endTime;
                                      }
                              } else {
                                const lastFullRestFinish = new Date(start45).getTime() + 12 * 24 * 60 * 60 * 1000 + 3 * 60 * 60 * 1000;
                                const nextRecordStart = new Date(nextRecord.startTime).getTime();
                                if(nextRecordStart < lastFullRestFinish) {
                                  const lastRestFinish = new Date(start24);
                                  const lastNewDate = lastRestFinish.getTime() + 6 * 24 * 60 * 60 * 1000;
                                  const recordStart = new Date(record.startTime);
                                  const NewDatenextRecordStart = recordStart.getTime();
                                  if(NewDatenextRecordStart < lastNewDate) {
                                    const hours = parseInt(record.SumTotalWeekRestTime.split(":")[0], 10);
                                    if(hours >= 24) {
                                      current24h = record;
                                      start24 = record.endTime;
                                    } 
                                  } else {
                                    ///OVDE ISRPAVITI
                                    count++
                                        let faultRecord = {
                                          Prekršaj: count,
                                          Vozač: record.driver,
                                          Kompanija: company.Name,
                                          Poruka: `Nema puni nedeljni odmor, treba imati 45h dana ${formatSRBTime(record.endTime)}! Odmor je trajao samo ${record.SumTotalWeekRestTime}!`,
                                        };
                                      arrayLessWeekRest.push(faultRecord);     
                                      start24 = 0;
                                      current24h = 0;  
                                      start45 = record.endTime;
                                      current45h = record;                                                      
                                    }
                                } else {
                                  count++
                                        let faultRecord = {
                                          Prekršaj: count,
                                          Vozač: record.driver,
                                          Kompanija: company.Name,
                                          Poruka: `Nema puni nedeljni odmor, treba imati 45h dana ${formatSRBTime(record.startTime)}! Odmor je trajao samo ${record.SumTotalWeekRestTime}!`,
                                        };
                                      arrayLessWeekRest.push(faultRecord);     
                                      start24 = 0;
                                      current24h = 0;  
                                      start45 = record.endTime;
                                      current45h = record;  
                                }  
                              }
                            }
                          }
                        } else  {
                                if(current45h == 0 && current24h == 0) {
                                  if(!nextRecord){
                                    let zeroDayFrom = new Date(firstValue.StartTime).getTime();
                                    let timeTo = new Date(record.startTime).getTime();
                                    let timeDifference = timeTo - zeroDayFrom

                                        if(timeDifference >= 6 * 24 * 60 * 60 * 1000) {
                                                
                                                const newDate = new Date(zeroDayFrom + 6 * 24 * 60 * 60 * 1000);
                                                const timeElapsed = new Date(record.startTime).getTime() - new Date(newDate).getTime();
                                                const formattedDate = formatLocalTime(newDate.toISOString()).split("T")[0];
                                                const formattedTime = formatLocalTime(newDate.toISOString()).split("T")[1].slice(0, -8);
                                                count++
                                                let faultRecord = {
                                                  Prekršaj: count,
                                                  Vozač: record.driver,
                                                  Kompanija: company.Name,
                                                  Poruka: `Kasno započet nedelji odmor dana ${formatSRBTime(record.startTime)}! Trebao je početi dana ${formatSRBTime(formattedDate)}, vreme ${formattedTime}. Kasnio započeo za ${formatTime(timeElapsed)}!`,
                                                };
                                                arrayLessWeekRest.push(faultRecord);
                                                start24 = record.endTime;
                                                current24h = record;

                                        } else {
                                          count++
                                        let faultRecord = {
                                            Prekršaj: count,
                                            Vozač: record.driver,
                                            Kompanija: company.Name,
                                            Poruka: `Nema dovoljni nedeljni odmor dana ${formatSRBTime(record.endTime)}! Odmor je trajao samo ${record.SumTotalWeekRestTime}.`,
                                          };
                                          arrayLessWeekRest.push(faultRecord);
                                          start24 = record.endTime;
                                          current24h = record;
                                        }

                                  } else {
                                    const lastRestFinish = new Date(firstValue.StartTime);
                                    const lastNewDate = lastRestFinish.getTime() + 6 * 24 * 60 * 60 * 1000;
                                    const nextRecordStart = new Date(nextRecord.startTime);
                                    const NewDatenextRecordStart = nextRecordStart.getTime();
                                    if(NewDatenextRecordStart < lastNewDate) {
                                        continue;
                                    } else {
                                      const restDuration = parseInt(record.SumTotalWeekRestTime.split(":")[0], 10);
                                      if (restDuration < 24) {
                                        count++
                                        let faultRecord = {
                                            Prekršaj: count,
                                            Vozač: record.driver,
                                            Kompanija: company.Name,
                                            Poruka: `Nema dovoljni nedeljni odmor dana ${formatSRBTime(record.endTime)}! Odmor je trajao samo ${record.SumTotalWeekRestTime}.`,
                                          };
                                        
                                        arrayLessWeekRest.push(faultRecord);     
                                        start24 = record.endTime;
                                        current24h = record;                                                      
                                      }
                                    }
                                  }
                            } else if(current45h != 0 && current24h == 0) {
                              if(!nextRecord){
                                let zeroDayFrom = new Date(start45).getTime();
                                let timeTo = new Date(record.startTime).getTime();
                                let timeDifference = timeTo - zeroDayFrom

                                    if(timeDifference >= 6 * 24 * 60 * 60 * 1000) {
                                      
                                      const newDate = new Date(zeroDayFrom + 6 * 24 * 60 * 60 * 1000);
                                      const timeElapsed = new Date(record.startTime).getTime() - new Date(newDate).getTime();
                                      const formattedDate = formatLocalTime(newDate.toISOString()).split("T")[0];
                                      const formattedTime = formatLocalTime(newDate.toISOString()).split("T")[1].slice(0, -8);
                                      count++
                                      let faultRecord = {
                                        Prekršaj: count,
                                        Vozač: record.driver,
                                        Kompanija: company.Name,
                                        Poruka: `Kasno započet nedelji odmor dana ${formatSRBTime(record.startTime)}! Trebao je početi dana ${formatSRBTime(formattedDate)}, vreme ${formattedTime}. Kasnio započeo za ${formatTime(timeElapsed)}!`,
                                      };
                                      arrayLessWeekRest.push(faultRecord);
                                      start24 = record.endTime;
                                      current24h = record;
                                    } else {
                                      start24 = record.endTime;
                                      current24h = record;
                                      continue;
                                    }
                              } else {
                                      const lastRestFinish = new Date(start45);
                                      const lastNewDate = lastRestFinish.getTime() + 6 * 24 * 60 * 60 * 1000;
                                      const nextRecordStart = new Date(nextRecord.startTime);
                                      const NewDatenextRecordStart = nextRecordStart.getTime();
                                      if(NewDatenextRecordStart < lastNewDate) {
                                        continue;
                                      } else {
                                        if(hours < 22) {
                                          const last24 = new Date(start45).getTime();
                                          const next = new Date(nextRecord.startTime).getTime();
                                          let elapesdTillSecond = next - last24;
                                          if(elapesdTillSecond > 6 * 24 * 60 * 60 * 1000 && elapesdTillSecond < 7 * 24 * 60 * 60 * 1000) {  
                                            const originalDate = new Date(start45);
                                            const newDate = new Date(originalDate.getTime() + 6 * 24 * 60 * 60 * 1000);
                                            const timeElapsed = next - new Date(newDate).getTime();

                                            
                                            const formattedDate = formatLocalTime(newDate.toISOString()).split("T")[0];
                                            const formattedTime = formatLocalTime(newDate.toISOString()).split("T")[1].slice(0, -8);
                                            count++
                                                    let faultRecord = {
                                                      Prekršaj: count,
                                                      Vozač: record.driver,
                                                      Kompanija: company.Name,
                                                      Poruka: `Kasno započet PUN nedeljni odmor ${formatSRBTime(nextRecord.startTime)}! Trebao je početi dana ${formatSRBTime(formattedDate)}, vreme ${formattedTime}. Kasnio započeo za ${formatTime(timeElapsed)}!`,
                                                    };
                                            arrayLessWeekRest.push(faultRecord);
                                            start45 = nextRecord.endTime;
                                            current45h = nextRecord;
                                            start24 = 0;
                                            current24h = 0;
                                          } else {
                                                count++
                                                let faultRecord = {
                                                  Prekršaj: count,
                                                  Vozač: record.driver,
                                                  Kompanija: company.Name,
                                                  Poruka: `Nema dovoljni nedeljni odmor ${formatSRBTime(record.endTime)}! Odmor je trajao samo ${record.SumTotalWeekRestTime}.`,
                                                }
                                              arrayLessWeekRest.push(faultRecord);     
                                              start24 = record.endTime;
                                              current24h = record;   
                                          } 
                                        } else {
                                          count++
                                          let faultRecord = {
                                            Prekršaj: count,
                                            Vozač: record.driver,
                                            Kompanija: company.Name,
                                            Poruka: `Nema dovoljni nedeljni odmor ${formatSRBTime(record.endTime)}! Odmor je trajao samo ${record.SumTotalWeekRestTime}.`,
                                          }
                                        arrayLessWeekRest.push(faultRecord);     
                                        start24 = record.endTime;
                                        current24h = record;   
                                        }        
                                      }
                              } 
                            } else if (current45h == 0 && current24h != 0) {
                                  if(!nextRecord){
                                    let zeroDayFrom = new Date(start24).getTime();
                                    let timeTo = new Date(record.startTime).getTime();
                                    let timeDifference = timeTo - zeroDayFrom

                                        if(timeDifference >= 6 * 24 * 60 * 60 * 1000) {
                                          
                                          const newDate = new Date(zeroDayFrom + 6 * 24 * 60 * 60 * 1000);
                                          const timeElapsed = new Date(record.startTime).getTime() - new Date(newDate).getTime();
                                          const formattedDate = formatLocalTime(newDate.toISOString()).split("T")[0];
                                          const formattedTime = formatLocalTime(newDate.toISOString()).split("T")[1].slice(0, -8);
                                          count++
                                          let faultRecord = {
                                            Prekršaj: count,
                                            Vozač: record.driver,
                                            Kompanija: company.Name,
                                            Poruka: `Kasno započet nedelji odmor dana ${formatSRBTime(record.startTime)}! Trebao je početi dana ${formatSRBTime(formattedDate)}, vreme ${formattedTime}. Kasnio započeo za ${formatTime(timeElapsed)}!`,
                                          };
                                          arrayLessWeekRest.push(faultRecord);
                                          current24h = record;
                                          start24 = record.endTime;
                                        } else {
                                          let dateEND = new Date(lastValue.EndTime).getTime();
                                          let difference = dateEND - zeroDayFrom
                                          if(difference > 6 * 24 * 60 * 60 * 1000) {

                                            count++
                                            let faultRecord = {
                                              Prekršaj: count,
                                              Vozač: record.driver,
                                              Kompanija: company.Name,
                                              Poruka: `Nema dovoljni nedeljni odmor, potrebna 45h dana ${formatSRBTime(record.endTime)}! Odmor je trajao samo ${record.SumTotalWeekRestTime}.`,
                                            };
                                            arrayLessWeekRest.push(faultRecord);
                                            current24h = record;
                                            start24 = record.endTime;
                                          } 
                                        }//////////////
                                  } else {
                                          const lastRestFinish = new Date(start24);
                                          const lastNewDate = lastRestFinish.getTime() + 6 * 24 * 60 * 60 * 1000;
                                          const nextRecordStart = new Date(nextRecord.startTime);
                                          const NewDatenextRecordStart = nextRecordStart.getTime();
                                          if(NewDatenextRecordStart < lastNewDate) {
                                              continue;
                                          } else {
                                            count++
                                                let faultRecord = {
                                                  Prekršaj: count,
                                                  Vozač: record.driver,
                                                  Kompanija: company.Name,
                                                  Poruka: `Nema dovoljni nedeljni odmor dana ${formatSRBTime(record.endTime)}! Odmor je trajao samo ${record.SumTotalWeekRestTime}.`,
                                                };
                                              
                                              arrayLessWeekRest.push(faultRecord);     
                                              start24 = record.endTime;
                                              current24h = record;                                                      
                                            }
                                          
                                        }
                            } else if (current45h != 0 && current24h != 0) { 
                              if(!nextRecord){
                                const last45 = new Date(start45).getTime();
                                const now45 = new Date(lastValue.EndTime).getTime();
                                let elapesdTillSecond45 = now45 - last45;
                                if(elapesdTillSecond45 > timeBeforeSecond45) {  
                                  const originalDate = new Date(last45);
                                  const newDate = new Date(originalDate.getTime() + 12 * 24 * 60 * 60 * 1000 + 3 * 60 * 60 * 1000);
                                  const timeElapsed = new Date(record.startTime).getTime() - new Date(newDate).getTime();
                                  const formattedDate = formatLocalTime(newDate.toISOString()).split("T")[0];
                                  const formattedTime = formatLocalTime(newDate.toISOString()).split("T")[1].slice(0, -8);
                                  count++
                                          let faultRecord = {
                                            Prekršaj: count,
                                            Vozač: record.driver,
                                            Kompanija: company.Name,
                                            Poruka: `Kasno započet PUN nedeljni odmor i nema potrebnu 45h dana ${formatSRBTime(record.startTime)}! Trebao je početi dana ${formatSRBTime(formattedDate)}, vreme ${formattedTime}. Kasnio započeo za ${formatTime(timeElapsed)}!`,
                                          };
                                  arrayLessWeekRest.push(faultRecord);
                                  start24 = record.endTime;
                                  current24h = record;
                                } else {
                                        let zeroDayFrom = new Date(start24).getTime();
                                        let timeTo = new Date(lastValue.EndTime).getTime();
                                        let timeDifference = timeTo - zeroDayFrom
                                        if(timeDifference < 6 * 24 * 60 * 60 * 1000){
                                          continue;
                                        } else {
                                              let zeroDayFrom = new Date(start24).getTime();
                                              let timeTo = new Date(record.startTime).getTime();
                                              let timeDifference = timeTo - zeroDayFrom
                                                if(timeDifference > 6 * 24 * 60 * 60 * 1000) {
                                                  
                                                  const newDate = new Date(zeroDayFrom + 6 * 24 * 60 * 60 * 1000);
                                                  const timeElapsed = new Date(record.startTime).getTime() - new Date(newDate).getTime();
                                                  const formattedDate = formatLocalTime(newDate.toISOString()).split("T")[0];
                                                  const formattedTime = formatLocalTime(newDate.toISOString()).split("T")[1].slice(0, -8);
                                                  count++
                                                  let faultRecord = {
                                                    Prekršaj: count,
                                                    Vozač: record.driver,
                                                    Kompanija: company.Name,
                                                    Poruka: `Kasno započet nedelji odmor i nema potrebnu 45h dana ${formatSRBTime(record.startTime)}! Trebao je početi dana ${formatSRBTime(formattedDate)}, vreme ${formattedTime}. Kasnio započeo za ${formatTime(timeElapsed)}!`,
                                                  };
                                                  arrayLessWeekRest.push(faultRecord);
                                                  current24h = record;
                                                  start24 = record.endTime;
                                                } else {
                                                  count++
                                                  let faultRecord = {
                                                    Prekršaj: count,
                                                    Vozač: record.driver,
                                                    Kompanija: company.Name,
                                                    Poruka: `Nema dovoljni nedeljni odmor, potrebna 45h dana ${formatSRBTime(record.endTime)}! Odmor je trajao samo ${record.SumTotalWeekRestTime}.`,
                                                  };
                                                  arrayLessWeekRest.push(faultRecord);
                                                  current24h = record;
                                                  start24 = record.endTime;
                                                }
                                        }  
                                }                                  
                              } else {
                                let zeroDayFrom = new Date(start24).getTime();
                                let timeTo = new Date(nextRecord.startTime).getTime();
                                let timeDifference = timeTo - zeroDayFrom
                                if(timeDifference < 6 * 24 * 60 * 60 * 1000){
                                  const hours = parseInt(nextRecord.SumTotalWeekRestTime.split(":")[0], 10);
                                        if(hours >= 24) {
                                          continue;
                                        } else {
                                          current24h = record;
                                          start24 = record.endTime;
                                        }
                                } else {
                                        const lastRestFinish = new Date(start24);
                                        const lastNewDate = lastRestFinish.getTime() + 6 * 24 * 60 * 60 * 1000;
                                        const nextRecordStart = new Date(nextRecord.startTime);
                                        const NewDatenextRecordStart = nextRecordStart.getTime();
                                        if(NewDatenextRecordStart < lastNewDate) {
                                          const hours = parseInt(nextRecord.SumTotalWeekRestTime.split(":")[0], 10);
                                          if(hours >= 24) {
                                            continue;
                                          } else {
                                            current24h = record;
                                            start24 = record.endTime;
                                          }
                                          
                                        } else {

                                          if(hours < 22) {
                                            const last24 = new Date(start24).getTime();
                                            const next = new Date(nextRecord.startTime).getTime();
                                            let elapesdTillSecond = next - last24;
                                            if(elapesdTillSecond > 6 * 24 * 60 * 60 * 1000 && elapesdTillSecond < 7 * 24 * 60 * 60 * 1000) {  
                                              const originalDate = new Date(start24);
                                              const newDate = new Date(originalDate.getTime() + 6 * 24 * 60 * 60 * 1000);
                                              const timeElapsed = next - new Date(newDate).getTime();

                                              
                                              const formattedDate = formatLocalTime(newDate.toISOString()).split("T")[0];
                                              const formattedTime = formatLocalTime(newDate.toISOString()).split("T")[1].slice(0, -8);
                                              count++
                                                      let faultRecord = {
                                                        Prekršaj: count,
                                                        Vozač: record.driver,
                                                        Kompanija: company.Name,
                                                        Poruka: `Kasno započet PUN nedeljni odmor ${formatSRBTime(nextRecord.startTime)}! Trebao je početi dana ${formatSRBTime(formattedDate)}, vreme ${formattedTime}. Kasnio započeo za ${formatTime(timeElapsed)}!`,
                                                      };
                                              arrayLessWeekRest.push(faultRecord);
                                              start45 = nextRecord.endTime;
                                              current45h = nextRecord;
                                              start24 = 0;
                                              current24h = 0;
                                            } else {
                                                  count++
                                                  let faultRecord = {
                                                    Prekršaj: count,
                                                    Vozač: record.driver,
                                                    Kompanija: company.Name,
                                                    Poruka: `Nema dovoljni nedeljni odmor ${formatSRBTime(record.endTime)}! Odmor je trajao samo ${record.SumTotalWeekRestTime}.`,
                                                  }
                                                arrayLessWeekRest.push(faultRecord);     
                                                start24 = record.endTime;
                                                current24h = record;   
                                            } 
                                          } else {
                                            count++
                                            let faultRecord = {
                                              Prekršaj: count,
                                              Vozač: record.driver,
                                              Kompanija: company.Name,
                                              Poruka: `Nema dovoljni nedeljni odmor ${formatSRBTime(record.endTime)}! Odmor je trajao samo ${record.SumTotalWeekRestTime}.`,
                                            }
                                          arrayLessWeekRest.push(faultRecord);     
                                          start24 = record.endTime;
                                          current24h = record;   
                                          }                                                 
                                      }
                                  }
                              }
                          }
                      }
                  }
                }
            }
    return arrayLessWeekRest;
  } 