/* eslint react/prop-types: 0 */
import React, { useState, useCallback, useEffect, useContext } from "react";
import "./App.css";
import { Route, Redirect, NavLink } from "react-router-dom";
import Header from "./Header/Header";
import Sidebar from "./Sidebar/Sidebar";
import Home from "./components/Home/home";
import Unos from "./components/Unos/Unos";
import Magacin from "./components/Magacin/magacin.js";
import Vozni_park from "./components/Vozni park/vozni park";
import Vozila_servis from "./components/Servis/vozila_servis";
import Servis from "./components/Servis/Servis";
import Radni_nalozi from "./components/Servis/Radni_nalozi";
import Zaposleni from "./components/Zaposleni/Zaposleni";
import Gorivo from "./components/Gorivo/gorivo";
import Kazne from "./KAZNE_DUJICK/Kazne.js";
import Info from "./components/Info/info";
// import Magacin1 from "./components/Magacin/Magacini/magacin1.js";
// import Magacin2 from "./components/Magacin/Magacini/magacin2.js";
// import Magacin3 from "./components/Magacin/Magacini/magacin3.js";
// import Borak from "./components/Magacin/Magacini/borak.js";
import Gorivo_izvestaj from "./components/Gorivo/izvestaji/gorivo izvestaj/gorivo_izvestaj";
import Gorivo_svipodaci from "./components/Gorivo/izvestaji/gorivo svi podaci/gorivo_svipodaci";
import Summary from "./Header/Summary";
import { AuthContext } from "./Header/auth - context";
import { useSelector, useDispatch } from "react-redux";
import Login from "./Login/login";
import Access_config from "./components/Settings/Access_config";
import Configuration from "./components/Settings/Configuration";
import { useHistory } from "react-router-dom";
import WarehouseConfig from "./components/Settings/warehouseConfig";
import PumpsConfig from "./components/Settings/pumpsConfig";

let logoutTimer;

function App() {
  const dispatch = useDispatch();
  const company = useSelector((state) => state.company);
  const userData = useSelector((state) => state.user);
  const role = useSelector((state) => state.user_role);
  const auth = useContext(AuthContext);
  const [token, setToken] = useState(false);
  const [userId, setUserId] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [user, setUser] = useState("");
  const [tokenExperation, setTokenExperation] = useState();
  let history = useHistory();

  const getUserLogged = (user) => {
    setUser(user);
  };
  
  const login = useCallback((uid, token, name, lastname, expirationDate) => {
    setToken(token);
    setUserId(uid);
    const tokenExpirationDate =
      expirationDate || new Date(new Date().getTime() + 1000 * 60 * 60 * 8);
    setTokenExperation(tokenExpirationDate);
    dispatch({
      type: "USER",
      value: {
        userId: uid,
        token: token,
        userName: name,
        userSurname: lastname,
        expiration: tokenExpirationDate.toISOString(),
      },
    });
  }, []);

  const logout = () => {
    let requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.token,
      },
      body: JSON.stringify({
        id: userData.userId,
      }),
    };
    fetch(process.env.REACT_APP_BACKEND_URL + "/users", requestOptions)
      .then(handleErrors)
      .then(() => {
        console.log("Sesija završena!");
        setUserId(null);
      })
      .catch((error) => {
        error.json().then((errorMessage) => {
          console.log(errorMessage);
        });
      });
    setToken(null);
    setTokenExperation(null);
    localStorage.removeItem("state");
    document.cookie = "count=; expires=" + new Date(0).toUTCString();
    history.push("/");
  };
  useEffect(() => {
    if (token && tokenExperation) {
      const remainingTime = tokenExperation.getTime() - new Date().getTime();
      logoutTimer = setTimeout(logout, remainingTime);
    } else {
      clearTimeout(logoutTimer);
    }
  }, [token, logout, tokenExperation]);

  useEffect(() => {
    if (user === "" && userData && company) {
      setUser({
        name: userData.userName,
        surname: userData.userSurname,
        Naziv: company.companyName,
      });
    }
    if (role !== null) {
      setUserRole(role.roleId);
    }
    if (
      userData &&
      userData.token &&
      new Date(userData.expiration) > new Date()
    ) {
      login(
        userData.userId,
        userData.token,
        userData.userName,
        userData.userSurname,
        new Date(userData.expiration)
      );
    }
  }, [login, userRole]);

  const handleErrors = (response) => {
    if (response.ok) {
      return response.json();
    } else {
      throw response;
    }
  };

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: !!token,
        token: token,
        userId: userId,
        login: login,
        logout: logout,
      }}
    >
      {!token && <Login login={login} getUser={getUserLogged} />}
      {token && (
        <div className="wrapper">
          <Header logout={logout} logged={token} user={user} />
          <div id="main">
            <Sidebar />
            <div className="workspace">
              <Route exact path="/" component={Summary} />
              <Route exact path="/tabla_zaduzenja" component={Home} />
              <Route exact path="/unos" component={Unos} />
              <Route exact path="/magacin" component={Magacin}>
                {/* <Redirect to="/magacin/1" />
                <Route exact path="/magacin/1" component={Magacin1} />
                <Route exact path="/magacin/2" component={Magacin2} />
                <Route exact path="/magacin/3" component={Magacin3} />
                <Route exact path="/magacin/4" component={Borak} /> */}
              </Route>
              <Route exact path="/vozni_park" component={Vozni_park} />
              <Route  path="/servis" component={Servis}>
                <div id="servis_nav">
                  <NavLink className="button servis" to="/servis/radni_nalozi">
                    RADNI NALOZI
                  </NavLink>
                  <NavLink className="button servis" to="/servis/vozila_servis">
                    SERVISIRANA VOZILA
                  </NavLink>
                </div>
                <Redirect to="/servis/radni_nalozi" />
                <Route
                  exact
                  path="/servis/radni_nalozi"
                  component={Radni_nalozi}
                />
                <Route
                  exact
                  path="/servis/vozila_servis"
                  component={Vozila_servis}
                />
              </Route>
              <Route exact path="/zaposleni" component={Zaposleni} />
              <Route path="/gorivo" component={Gorivo}>
                <div id="gorivo_nav">
                  <NavLink className="button gorivo" to="/gorivo/izvestaj">
                    IZVEŠTAJ
                  </NavLink>
                  <NavLink className="button gorivo" to="/gorivo/svi_podaci">
                    SVI PODACI
                  </NavLink>
                </div>
                <Redirect to="/gorivo/izvestaj" />
                <Route
                  exact
                  path="/gorivo/izvestaj"
                  component={Gorivo_izvestaj}
                />
                <Route
                  exact
                  path="/gorivo/svi_podaci"
                  component={Gorivo_svipodaci}
                />
              </Route>
              <Route exact path="/kazne" component={Kazne} />
              <Route exact path="/info" component={Info} />
              <Route path="/settings" component={Configuration}>
                <div id="settings_nav">
                  <NavLink
                    className="button settings"
                    to="/settings/configuration"
                  >
                    KONFIGURACIJA SISTEMA
                  </NavLink>
                  <NavLink
                    className="button settings"
                    to="/settings/access_config"
                  >
                    PODEŠAVANJA PRISTUPA
                  </NavLink>
                </div>
                <Route
                  exact
                  path="/settings/configuration"
                  component={Configuration}
                >
                  
                  <Redirect to="/settings/configuration/warehouses" />
                  <Route
                    exact
                    path="/settings/configuration/warehouses"
                    component={WarehouseConfig}
                  />
                  <Route
                    exact
                    path="/settings/access_config/pumps"
                    component={PumpsConfig}
                  />
                </Route>
                <Route
                exact
                path="/settings/access_config"
                component={Access_config}
              />
              <div id="servis_nav">
                    <NavLink
                      className="button servis"
                      to="/settings/configuration/warehouses"
                    >
                      MAGACINI
                    </NavLink>
                    <NavLink
                      className="button servis"
                      to="/settings/configuration/pumps"
                    >
                      PUMPE
                    </NavLink>
                  </div>
              </Route>
              <Route
                    exact
                    path="/settings/configuration/warehouses"
                    component={WarehouseConfig}
                  />
                  <Route
                    exact
                    path="/settings/configuration/pumps"
                    component={PumpsConfig}
                  />
            </div>
   
          </div>
        </div>
      )}
    </AuthContext.Provider>
  );
}

export default React.memo(App);
