export function processSmallDayRest(records, company, driversDB, isGradski, resUnknown) {

    let count = 0;

    const arrayWorkDay = [];
    let workDay = {
        driver: 0,
        startTime: 0,
        endTime: 0,
        avail: 0
    };
    const findDriver = (id) => {
        let driver = driversDB.find(driver => driver.Id === id)
        return driver.FullName;
    }
    function formatSRBTime(stringDate) {
      const date = new Date(stringDate);
      const formattedDate = `${date.getDate().toString().padStart(2, '0')}.${(date.getMonth() + 1).toString().padStart(2, '0')}.${date.getFullYear()}`;
      return formattedDate
    }

    for(let n=0; n<driversDB.length;n++){
      let driver = driversDB[n];
      let filtered = records.filter(item => item.DriverId === driver.Id)
      
    for (let m = 0; m < filtered.length; m++) {
        const record = filtered[m];
        const nextRecord = filtered[m + 1];
        const previousRecord = filtered[m - 1];

        if (record.Value.toLowerCase() === 'driving' || record.Value.toLowerCase() === 'work' || record.Value.toLowerCase() === 'availability') {
                                if(record.Value.toLowerCase() === 'work'){

                                      if(record.TotalTime < 5 * 60 * 60 * 1000){
                                                  if(!previousRecord) {
                                                          workDay.driver = record.DriverId;
                                                          workDay.startTime = record.StartTime;
                                                          workDay.endTime = record.EndTime;
                                                      } else {
                                                              if(!nextRecord){
                                                                if (workDay.startTime === 0) {
                                                                        workDay.driver = record.DriverId;
                                                                        workDay.startTime = record.StartTime;
                                                                        workDay.endTime = record.EndTime;
                                                                        if(workDay.driver != 0) {
                                                                          arrayWorkDay.push({ ...workDay });
                                                                          workDay = {
                                                                              driver: 0,
                                                                              startTime: 0,
                                                                              endTime: 0,
                                                                              avail: 0
                                                                          };
                                                                        }
                                                                    } else {
                                                                      if(nextRecord.Value.toLowerCase() === 'work'){
                                                                        const startTime = new Date(workDay.endTime).getTime();
                                                                        const endTime = new Date(nextRecord.StartTime).getTime();
                                                                        const timeDifference = endTime - startTime;
                                                                        if(timeDifference > 7 * 60 * 60 * 1000 + 30 * 60 * 1000) {
                                                                          continue;
                                                                        } else {
                                                                          workDay.endTime = record.EndTime;
                                                                        }
                                                                      } else {
                                                                        workDay.endTime = record.EndTime;
                                                                      }
                                                                    }
                                                          } else {
                                                            if(workDay.startTime === 0) {
                                                              workDay.driver = record.DriverId;
                                                              workDay.startTime = record.StartTime;
                                                              workDay.endTime = record.EndTime;
                                                            } else {
                                                              workDay.endTime = record.EndTime;
                                                            }
                                                            
                                                          }
                                                    } 
                                       } else {
                                                  if(previousRecord) {
                                                      if(previousRecord.Value.toLowerCase() === 'work') {
                                                        const startTime = new Date(previousRecord.endTime).getTime();
                                                        const endTime = new Date(record.EndTime).getTime();
                                                        const timeDifference = endTime - startTime;
                                                        if(timeDifference > 7 * 60 * 60 * 1000 + 30 * 60 * 1000) {
                                                          continue;
                                                        } else {
                                                          workDay.endTime = record.EndTime;
                                                        }
                                                          }
                                                           else {
                                                            continue
                                                          }         
                                                } else {
                                                  if(!nextRecord) {
                                                    if(workDay.driver != 0) {
                                                      arrayWorkDay.push({ ...workDay });
                                                      workDay = {
                                                          driver: 0,
                                                          startTime: 0,
                                                          endTime: 0,
                                                          avail: 0
                                                      };
                                                    }
                                                   
                                                  } else { 
                                                  continue
                                                  }         

                                                }
                                        } 

                                           
                                      
                                          
                                             
                                } else if (record.Value.toLowerCase() === 'availability') {
                                  if(record.TotalTime < 5 * 60 * 60 * 1000){
                                    if(!previousRecord) {
                                            workDay.driver = record.DriverId;
                                            workDay.startTime = record.StartTime;
                                            workDay.endTime = record.EndTime;
                                            workDay.avail += parseInt(record.TotalTime);
                                        } else {
                                                if(!nextRecord){
                                                  if (workDay.startTime === 0) {
                                                          workDay.driver = record.DriverId;
                                                          workDay.startTime = record.StartTime;
                                                          workDay.endTime = record.EndTime;
                                                          workDay.avail += parseInt(record.TotalTime);
                                                          if(workDay.driver != 0) {
                                                            arrayWorkDay.push({ ...workDay });
                                                            workDay = {
                                                                driver: 0,
                                                                startTime: 0,
                                                                endTime: 0,
                                                                avail: 0
                                                            };
                                                          }
                                                      } else {
                                                        workDay.driver = record.DriverId;
                                                        workDay.endTime = record.EndTime;
                                                        workDay.avail += parseInt(record.TotalTime);
                                                        if(workDay.driver != 0) {
                                                          arrayWorkDay.push({ ...workDay });
                                                          workDay = {
                                                              driver: 0,
                                                              startTime: 0,
                                                              endTime: 0,
                                                              avail: 0
                                                          };
                                                        }
                                                      }
                                            }else {
                                              workDay.avail += parseInt(record.TotalTime);
                                              if(workDay.startTime === 0) {
                                                workDay.driver = record.DriverId;
                                                workDay.startTime = record.StartTime;
                                                workDay.endTime = record.EndTime;
                                                
                                              } else {
                                                workDay.endTime = record.EndTime;
                                          
                                              }
                                            }
                                      } 
                                    } else {
                                            if(previousRecord) {
                                                if(previousRecord.Value.toLowerCase() === 'availability') {
                                                  const startTime = new Date(previousRecord.EndTime).getTime();
                                                  const endTime = new Date(record.EndTime).getTime();
                                                  const timeDifference = endTime - startTime;
                                                  if(timeDifference > 7 * 60 * 60 * 1000 + 30 * 60 * 1000) {
                                                    continue;
                                                  } else {
                                                    workDay.endTime = record.EndTime;
                                                  }
                                                    } else {
                                                      workDay.avail += parseInt(record.TotalTime);
                                                    }         
                                          } else {
                                            workDay.avail += parseInt(record.TotalTime);
                                            if(!nextRecord) {
                                              arrayWorkDay.push({ ...workDay });
                                                      workDay = {
                                                      driver: 0,
                                                      startTime: 0,
                                                      endTime: 0,
                                                      avail: 0
                                                      };
                                            
                                            } else { 
                                            continue
                                            }         

                                      }
                                  } 
                                } else if (record.Value.toLowerCase() === 'driving') {
                                        if (workDay.startTime === 0) {
                                          workDay.driver = record.DriverId;
                                          workDay.startTime = record.StartTime;
                                          workDay.endTime = record.EndTime;
                                      } else {
                                        workDay.endTime = record.EndTime;
                                      }
                                }

           
        } else if (record.Value.toLowerCase() === 'break/rest') {
            
          if(!previousRecord) {
            continue;
          } else {
            if (workDay.startTime !== 0) {
              if(!nextRecord) {
                // if(previousRecord.Value.toLowerCase() === 'break/rest' || previousRecord.Value.toLowerCase() === 'unknown'){

                // }
                if(workDay.driver != 0) {
                  arrayWorkDay.push({ ...workDay });
                  workDay = {
                      driver: 0,
                      startTime: 0,
                      endTime: 0,
                      avail: 0
                  };
                }
                } else {

                  if(nextRecord.Value.toLowerCase() === 'break/rest') {
                    let dayNext = nextRecord.StartTime.split("T")[0];
                    let dayCurr = record.StartTime.split("T")[0];
                    if(dayNext == dayCurr) {
                      if(parseInt(record.TotalTime) < 2 * 60 * 60 * 1000){
                        workDay.endTime = record.EndTime;
                      }
                    }
                    if((parseInt(record.TotalTime) + parseInt(nextRecord.TotalTime) > 5 * 60 * 60 * 1000 + 30 * 60 * 1000) && ((new Date(nextRecord.EndTime).getTime() - new Date(workDay.startTime).getTime()) > 12 * 60 * 60 * 1000 ) ) {
                      if(workDay.driver != 0) {
                        arrayWorkDay.push({ ...workDay });
                        workDay = {
                            driver: 0,
                            startTime: 0,
                            endTime: 0,
                            avail: 0
                        };
                      }
                     } else {
                      workDay.endTime = record.EndTime;
                     }
                  } else {
                    
                    if (((new Date(record.EndTime).getTime() - new Date(workDay.startTime).getTime()) < 12 * 60 * 60 * 1000 ) ) {
                        workDay.endTime = record.EndTime;
                    } else {
                      if(workDay.driver != 0) {
                        if(workDay.avail > 3 * 60 * 60 * 1000 || (parseInt(record.TotalTime) < 60 * 60 * 1000 && record.ActivityDate == nextRecord.ActivityDate)){
                          workDay.endTime = record.EndTime;
                        } else {
                          arrayWorkDay.push({ ...workDay });
                          workDay = {
                              driver: 0,
                              startTime: 0,
                              endTime: 0,
                              avail: 0
                          };
                        }
                       
                      }
                    }   
                  }
              }
            } else {
              if(!nextRecord){
                if(workDay.driver != 0) {
                  arrayWorkDay.push({ ...workDay });
                  workDay = {
                      driver: 0,
                      startTime: 0,
                      endTime: 0,
                      avail: 0
                  };
                }
              } else {
                if(previousRecord.Value.toLowerCase() === 'break/rest') {
                  let dayPrev = previousRecord.StartTime.split("T")[0];
                  let dayCurr = record.StartTime.split("T")[0];
                  let dayNext;

                  

                  if(dayPrev == dayCurr) {
                        workDay.driver = record.DriverId;
                        workDay.startTime = record.StartTime;
                        workDay.endTime = record.EndTime;

                        if(nextRecord){
                          if(nextRecord.Value.toLowerCase() === 'break/rest') {
                          dayNext = nextRecord.StartTime.split("T")[0];
                          if(dayCurr != dayNext){
                            workDay = {
                              driver: 0,
                              startTime: 0,
                              endTime: 0,
                              avail: 0
                          };
                          } else {
                            let begin = new Date(record.StartTime).getTime();
                            let end = new Date(record.EndTime).getTime();
                            if((end - begin) > 7 * 60 * 60 * 1000){
                              workDay = {
                                driver: 0,
                                startTime: 0,
                                endTime: 0,
                                avail: 0
                            };
                            }
                          }
                        } else {
                          let begin = new Date(record.StartTime).getTime();
                          let end = new Date(record.EndTime).getTime();
                          if((end - begin) > 8 * 60 * 60 * 1000){
                            workDay = {
                              driver: 0,
                              startTime: 0,
                              endTime: 0,
                              avail: 0
                          };
                          }
                        }
                      }
                  }
                }
              }
            }
          }


        } else if (record.Value.toLowerCase() === 'unknown') {
              if(nextRecord){
                if(nextRecord.Value.toLowerCase() === 'unknown') {
                  if(parseInt(record.TotalTime) + parseInt(nextRecord.TotalTime) < 5 * 60 * 60 * 1000 + 30 * 60 * 1000) {
                    continue;
                  } else {
                    if(workDay.driver != 0) {
                      arrayWorkDay.push({ ...workDay });
                      workDay = {
                          driver: 0,
                          startTime: 0,
                          endTime: 0,
                          avail: 0
                      };
                    }
                  }
                } else {
                  if(parseInt(record.TotalTime) < 5 * 60 * 60 * 1000 + 30 * 60 * 1000) {
                    continue;
                  } else {
                    if(workDay.driver != 0) {
                      arrayWorkDay.push({ ...workDay });
                      workDay = {
                          driver: 0,
                          startTime: 0,
                          endTime: 0,
                          avail: 0
                      };
                    }
                  }
                }
              
              } else {
                if(record.TotalTime < 1 * 60 * 60 * 1000) {
                  continue;
                } else {
                  if (!nextRecord || nextRecord.Value.toLowerCase() === 'driving' || nextRecord.Value.toLowerCase() === 'work' || nextRecord.Value.toLowerCase() === 'availability') {
                  
                    if (workDay.startTime !== 0) {
                        arrayWorkDay.push({ ...workDay });
                        workDay = {
                            driver: 0,
                            startTime: 0,
                            endTime: 0,
                            avail: 0
                        };
                    }
                }
                }
              }
       
         
        }
    }
    }
 

    const calculate9orLess = (data) => {
     
      let arrayFaults = [];

      


      for(let i=0; i<driversDB.length;i++){
          let driver = driversDB[i];
          let filtered = data.filter(item => item.driver === driver.Id)

          let startWeek;
          let count9h = 0

          for (let j = 0; j < filtered.length; j++) { 

           
            let workDayForCalculation = 24 * 60 * 60 * 1000;
            const record = filtered[j];
            const nextRecord = filtered[j+1];
            //const nextRecord2 = filtered[j+2];
            
           
            if(!isGradski){
              let startRest = new Date(record.endTime).getTime()
              let endRest;
              if(nextRecord){
                endRest = new Date(nextRecord.startTime).getTime()
                if(endRest - startRest > 24 * 60 * 60 * 1000){
                  count9h = 0;
                  if(startWeek != record){
                    startWeek = record.startTime;
                  }
                }
              }
              if(record.avail > 3 * 60 * 60 * 1000){
                console.log(record)
                workDayForCalculation = 30 * 60 * 60 * 1000;
              }
            }

            let wholeDay24h = new Date(record.startTime).getTime() + workDayForCalculation;

            //const startWorkDayCurrent = new Date(record.startTime).getHours()
            const endWorkDayCurrent = new Date(record.endTime).getTime()
          
           

            // const startWorkDay =  new Date(record.startTime).getTime();
            // const endWorkDay =  new Date(record.endTime).getTime();
            const timeDifferenceDay = wholeDay24h - endWorkDayCurrent;
            const hoursDay = String(Math.floor(timeDifferenceDay / (60 * 60 * 1000))).padStart(2, '0');
            const minutesDay = String(Math.floor((timeDifferenceDay % (60 * 60 * 1000)) / (60 * 1000))).padStart(2, '0');
         
            if(nextRecord){
              const startWork =  new Date(record.startTime).getTime();
              const endWork =  new Date(record.endTime).getTime();
              const startTimeRest = new Date(record.endTime).getTime();
              const endTimeRest = new Date(nextRecord.startTime).getTime();
              
              // const day = nextRecord.startTime.split("T")[0];
              const timeDifference = endTimeRest - startTimeRest;
              const timeDifferenceWork = endWork - startWork;
             
              const hoursWorked = String(Math.floor(timeDifferenceWork / (60 * 60 * 1000))).padStart(2, '0');
              const minutesWorked = String(Math.floor((timeDifferenceWork % (60 * 60 * 1000)) / (60 * 1000))).padStart(2, '0');

              if(timeDifferenceWork > 15 * 60 * 60 * 1000){
                if(isGradski) {

                  if(timeDifference < 9 * 60 * 60 * 1000) {
                    count++
                    let faultData = {
                      Prekršaj: count,
                      Vozač: findDriver(record.driver),
                      Kompanija: company.Name,
                      Poruka: `Nema devetku u 24h. Prekoračenje radnog dana ${formatSRBTime(record.endTime)}. Dnevni odmor je bio samo ${hoursDay}:${minutesDay}!`
                    }
                    arrayFaults.push(faultData)
                  } else {
                    if(timeDifferenceDay < 9 * 60 * 60 * 1000 && endTimeRest > wholeDay24h) {
                      count++
                      let faultData = {
                        Prekršaj: count,
                        Vozač: findDriver(record.driver),
                        Kompanija: company.Name,
                        Poruka: `Nema devetku u 24h. Prekršaj dana ${formatSRBTime(record.endTime)}. Dnevni odmor u 24h je samo ${hoursDay}:${minutesDay}!`
                      }
                      arrayFaults.push(faultData)
                  }
                  }
                  // if(dayEnd - dayStart > 36 * 60 * 60 * 1000) {
                  //   count++
                  //   let faultData = {
                  //     Prekršaj: count,
                  //     Vozač: findDriver(record.driver),
                  //     Kompanija: company.Name,
                  //     Poruka: `Nema devetku u 2 dana za redom. Prekoračenje radnog dana ${formatSRBTime(record.endTime)}, radni dan trajao ${hoursDay}:${minutesDay}!`
                  //   }
                  //   arrayFaults.push(faultData)
                  // } else {
                  //   count++
                  //   let faultData = {
                  //     Prekršaj: count,
                  //     Vozač: findDriver(record.driver),
                  //     Kompanija: company.Name,
                  //     Poruka: `Nema devetku u 24h. Prekoračenje radnog dana ${formatSRBTime(record.endTime)}, radni dan trajao ${hoursDay}:${minutesDay}!`
                  //   }
                  //   arrayFaults.push(faultData)
                  // }
        
                } else {
                  if(timeDifferenceWork > 36 * 60 * 60 * 1000) {
                    count++
                    let faultData = {
                      Prekršaj: count,
                      Vozač: findDriver(record.driver),
                      Kompanija: company.Name,
                      Poruka: `Nema dnevni odmor u 2 dana za redom. Prekoračenje radnog dana ${formatSRBTime(record.endTime)}, radni dan trajao ${hoursWorked}:${minutesWorked}!`
                    }
                    arrayFaults.push(faultData)
                  } else {
                    if(timeDifferenceWork > 15 * 60 * 60 * 1000 && timeDifferenceWork <= 21 * 60 * 60 * 1000 + 15 * 60 * 1000) {
                        if(record.avail  <= 3 * 60 * 60 * 1000) {
                          let hours = String(Math.floor(timeDifference / (60 * 60 * 1000))).padStart(2, '0');
                          let minutes = String(Math.floor((timeDifference % (60 * 60 * 1000)) / (60 * 1000))).padStart(2, '0');
                          if(timeDifference < 9 * 60 * 60 * 1000) {
                            count9h++
                                if(count9h > 2) {      
                                  count++
                                  let faultData = {
                                    Prekršaj: count,
                                    Vozač: findDriver(record.driver),
                                    Kompanija: company.Name,
                                    Poruka: `Prekršaj dana ${formatSRBTime(record.endTime)}. Nema puni dnevni odmor u radnoj nedelji. ${count9h} devetka, a trajao ${hours}:${minutes}!`
                                  }
                                  arrayFaults.push(faultData)
                                } else {
                                    count++
                                            let faultData = {
                                              Prekršaj: count,
                                              Vozač: findDriver(record.driver),
                                              Kompanija: company.Name,
                                              Poruka: `Nema dovoljni dnevni odmor dana ${formatSRBTime(record.endTime)}, trajao ${hours}:${minutes}!`
                                            }
                                            arrayFaults.push(faultData)
                                }    
                          } else if(timeDifference > 9 * 60 * 60 * 1000 && timeDifference < 11 * 60 * 60 * 1000) {
                            count9h++
                            if(count9h > 2) {
                              count++
                              let faultData = {
                                Prekršaj: count,
                                Vozač: findDriver(record.driver),
                                Kompanija: company.Name,
                                Poruka: `Prekršaj dana ${formatSRBTime(record.endTime)}. Nema puni dnevni odmor u radnoj nedelji. ${count9h} devetka, a trajao ${hours}:${minutes}!`
                              }
                              arrayFaults.push(faultData)
                            }    
                          } else {
                            if(timeDifferenceDay < 9 * 60 * 60 * 1000 && endTimeRest > wholeDay24h) {
                              count++
                              let faultData = {
                                Prekršaj: count,
                                Vozač: findDriver(record.driver),
                                Kompanija: company.Name,
                                Poruka: `Nema devetku u ${wholeDay24h == 24 * 60 * 60 * 1000 ? '24' : '30'}h. Prekršaj dana ${formatSRBTime(record.endTime)}. Dnevni odmor u ${wholeDay24h == 24 * 60 * 60 * 1000 ? '24' : '30'}h je samo ${hoursDay}:${minutesDay}!`
                              }
                              arrayFaults.push(faultData)
                          }
                          } 
                        } else {
                          let hours = String(Math.floor(timeDifference / (60 * 60 * 1000))).padStart(2, '0');
                          let minutes = String(Math.floor((timeDifference % (60 * 60 * 1000)) / (60 * 1000))).padStart(2, '0');
                         //OVDE UKOLIKO JE UDVOJENA POSADA DORADITI
                         if(timeDifference < 9 * 60 * 60 * 1000) {
                            count++
                            let faultData = {
                              Prekršaj: count,
                              Vozač: findDriver(record.driver),
                              Kompanija: company.Name,
                              Poruka: `Udvojena posada. Nema dovoljni dnevni odmor dana ${formatSRBTime(record.endTime)}, trajao ${hours}:${minutes}!`
                            }
                            arrayFaults.push(faultData)
                         } else if(timeDifference > 9 * 60 * 60 * 1000 && timeDifference < 11 * 60 * 60 * 1000) {
                          count9h++
                          if(timeDifferenceDay < 9 * 60 * 60 * 1000 && endTimeRest > wholeDay24h) {
                            count++
                            let faultData;
                            if(count9h > 2) {
                              faultData = {
                                Prekršaj: count,
                                Vozač: findDriver(record.driver),
                                Kompanija: company.Name,
                                Poruka: `Nema devetku u ${wholeDay24h == 24 * 60 * 60 * 1000 ? '24' : '30'}h. Prekršaj dana ${formatSRBTime(record.endTime)}. Ima 2 prekršaja u istom danu: 1. Dnevni odmor u ${wholeDay24h == 24 * 60 * 60 * 1000 ? '24' : '30'}h je samo ${hoursDay}:${minutesDay}, 2. Broj devetki za redom: ${count9h}!`
                              }
                            } else {
                              faultData = {
                                Prekršaj: count,
                                Vozač: findDriver(record.driver),
                                Kompanija: company.Name,
                                Poruka: `Nema devetku u ${wholeDay24h == 24 * 60 * 60 * 1000 ? '24' : '30'}h. Prekršaj dana ${formatSRBTime(record.endTime)}. Dnevni odmor u ${wholeDay24h == 24 * 60 * 60 * 1000 ? '24' : '30'}h je samo ${hoursDay}:${minutesDay}!`
                              }
                            }
                            arrayFaults.push(faultData)
                        } else {
                          if(count9h > 2) {
                            count++
                            let faultData = {
                              Prekršaj: count,
                              Vozač: findDriver(record.driver),
                              Kompanija: company.Name,
                              Poruka: `Prekršaj dana ${formatSRBTime(record.endTime)}. Nema puni dnevni odmor u radnoj nedelji. Broj devetki za redom: ${count9h}!`
                            }
                            arrayFaults.push(faultData)
                          }
                        }
                         }
                        }
                    } else if(timeDifferenceWork > 21 * 60 * 60 * 1000 + 15 * 60 * 1000) {
                      let hours = String(Math.floor(timeDifferenceWork / (60 * 60 * 1000))).padStart(2, '0');
                      let minutes = String(Math.floor((timeDifferenceWork % (60 * 60 * 1000)) / (60 * 1000))).padStart(2, '0');
                      count++
                      let faultData = {
                        Prekršaj: count,
                        Vozač: findDriver(record.driver),
                        Kompanija: company.Name,
                        Poruka: `Udvojena posada. Nema devetku u 24h. Prekoračenje radnog dana ${formatSRBTime(record.endTime)}, radni dan trajao ${hours}:${minutes}!`
                      }
                      arrayFaults.push(faultData)
                    }
                  }
                }
              } else {
                  if(timeDifference < 9 * 60 * 60 * 1000) {
                
                    let hours = String(Math.floor(timeDifference / (60 * 60 * 1000))).padStart(2, '0');
                    let minutes = String(Math.floor((timeDifference % (60 * 60 * 1000)) / (60 * 1000))).padStart(2, '0');
                                  if(isGradski) {
                                    count++
                                    let faultData = {
                                      Prekršaj: count,
                                      Vozač: findDriver(record.driver),
                                      Kompanija: company.Name,
                                      Poruka: `Nema devetku u 24h. Prekršaj dana ${formatSRBTime(record.endTime)}. Dnevni odmor u 24h je samo ${hours}:${minutes}!`
                                    }
                                    arrayFaults.push(faultData)
                                  } else {
                                    count9h++
                                         if(count9h > 2) {
                                            count++
                                            let faultData = {
                                              Prekršaj: count,
                                              Vozač: findDriver(record.driver),
                                              Kompanija: company.Name,
                                              Poruka: `Prekršaj dana ${formatSRBTime(record.endTime)}. Nema puni dnevni odmor u radnoj nedelji. ${count9h} devetka, a trajao ${hours}:${minutes}!`
                                            }
                                            arrayFaults.push(faultData)
                                         } else {
                                              count++
                                                      let faultData = {
                                                        Prekršaj: count,
                                                        Vozač: findDriver(record.driver),
                                                        Kompanija: company.Name,
                                                        Poruka: `Nema dovoljni dnevni odmor dana ${formatSRBTime(record.endTime)}, trajao ${hours}:${minutes}!`
                                                      }
                                                      arrayFaults.push(faultData)
                                         }           
                                  }
                              // } else {
                              //     if(isGradski){
                              //       count++
                              //       if(nextRecord2){
                              //         let start = new Date(record.startTime).getTime() + 24 * 60 * 60 * 1000;
                              //         let end = new Date(nextRecord2.startTime).getTime();
                              //         if(end - start > 0) {
                              //           const startWork2 =  new Date(nextRecord.endTime).getTime();
                              //           const endWork2 =  new Date(record.startTime).getTime() + 24 * 60 * 60 * 1000;
                              //           let timeDifference2 = endWork2 - startWork2
                              //           if(timeDifference2 > timeDifference){
                              //             hours = String(Math.floor(timeDifference2 / (60 * 60 * 1000))).padStart(2, '0');
                              //             minutes = String(Math.floor((timeDifference2 % (60 * 60 * 1000)) / (60 * 1000))).padStart(2, '0');
                              //             let faultData = {
                              //               Prekršaj: count,
                              //               Vozač: findDriver(record.driver),
                              //               Kompanija: company.Name,
                              //               Poruka: `Nema dovoljni dnevni odmor dana ${formatSRBTime(nextRecord.startTime)}, trajao ${hours}:${minutes}!`
                              //             }
                              //             arrayFaults.push(faultData)
                              //           } else {
                              //             let faultData = {
                              //               Prekršaj: count,
                              //               Vozač: findDriver(record.driver),
                              //               Kompanija: company.Name,
                              //               Poruka: `Nema dovoljni dnevni odmor dana ${formatSRBTime(nextRecord.startTime)}, trajao ${hours}:${minutes}!`
                              //             }
                              //             arrayFaults.push(faultData)
                              //           }
                              //         } else {
                              //           const startWork2 =  new Date(nextRecord.startTime).getTime();
                              //           const endWork2 =  new Date(record.endTime).getTime();
                              //           let timeDifference2 = endWork2 - startWork2
                              //           if(timeDifference2 > timeDifference){
                              //             hours = String(Math.floor(timeDifference2 / (60 * 60 * 1000))).padStart(2, '0');
                              //             minutes = String(Math.floor((timeDifference2 % (60 * 60 * 1000)) / (60 * 1000))).padStart(2, '0');
                              //             let faultData = {
                              //               Prekršaj: count,
                              //               Vozač: findDriver(record.driver),
                              //               Kompanija: company.Name,
                              //               Poruka: `Nema dovoljni dnevni odmor dana ${formatSRBTime(nextRecord.startTime)}, trajao ${hours}:${minutes}!`
                              //             }
                              //             arrayFaults.push(faultData)
                              //           } else {
                              //             let faultData = {
                              //               Prekršaj: count,
                              //               Vozač: findDriver(record.driver),
                              //               Kompanija: company.Name,
                              //               Poruka: `Nema dovoljni dnevni odmor dana ${formatSRBTime(nextRecord.startTime)}, trajao ${hours}:${minutes}!`
                              //             }
                              //             arrayFaults.push(faultData)
                              //           }
                              //         }
                              //       } else {
                              //         let faultData = {
                              //           Prekršaj: count,
                              //           Vozač: findDriver(record.driver),
                              //           Kompanija: company.Name,
                              //           Poruka: `Nema dovoljni dnevni odmor dana ${formatSRBTime(nextRecord.startTime)}, trajao ${hours}:${minutes}!`
                              //         }
                              //         arrayFaults.push(faultData)
                              //       }  
                              //     } else {
                              //       if(timeDifferenceWork >= 21 * 60 * 60 * 1000) {
                              //           count++
                              //           let faultData = {
                              //             Prekršaj: count,
                              //             Vozač: findDriver(record.driver),
                              //             Kompanija: company.Name,
                              //             Poruka: `Nema dovoljni dnevni odmor dana ${formatSRBTime(nextRecord.startTime)}, trajao ${hours}:${minutes}!`
                              //           }
                              //           arrayFaults.push(faultData)
                              //       } else if(timeDifferenceWork >= 15 * 60 * 60 * 1000 && timeDifferenceWork < 21 * 60 * 60 * 1000){
                              //             if(record.avail + nextRecord.avail   < 3 * 60 * 60 * 1000) {
                              //               count++
                              //               if(nextRecord2){
                              //                 let start = new Date(record.startTime).getTime() + 24 * 60 * 60 * 1000;
                              //                 let end = new Date(nextRecord2.startTime).getTime();
                              //                 if(end - start > 0) {
                              //                   const startWork2 =  new Date(nextRecord.endTime).getTime();
                              //                   const endWork2 =  new Date(record.startTime).getTime() + 24 * 60 * 60 * 1000;
                              //                   let timeDifference2 = endWork2 - startWork2
                              //                   if(timeDifference2 > timeDifference){
                              //                     hours = String(Math.floor(timeDifference2 / (60 * 60 * 1000))).padStart(2, '0');
                              //                     minutes = String(Math.floor((timeDifference2 % (60 * 60 * 1000)) / (60 * 1000))).padStart(2, '0');
                              //                     let faultData = {
                              //                       Prekršaj: count,
                              //                       Vozač: findDriver(record.driver),
                              //                       Kompanija: company.Name,
                              //                       Poruka: `Nema dovoljni dnevni odmor dana ${formatSRBTime(nextRecord.startTime)}, trajao ${hours}:${minutes}!`
                              //                     }
                              //                     arrayFaults.push(faultData)
                              //                   } else {
                              //                     let faultData = {
                              //                       Prekršaj: count,
                              //                       Vozač: findDriver(record.driver),
                              //                       Kompanija: company.Name,
                              //                       Poruka: `Nema dovoljni dnevni odmor dana ${formatSRBTime(nextRecord.startTime)}, trajao ${hours}:${minutes}!`
                              //                     }
                              //                     arrayFaults.push(faultData)
                              //                   }
                              //                 } else {
                              //                   const startWork2 =  new Date(nextRecord.startTime).getTime();
                              //                   const endWork2 =  new Date(record.endTime).getTime();
                              //                   let timeDifference2 = endWork2 - startWork2
                              //                   if(timeDifference2 > timeDifference){
                              //                     hours = String(Math.floor(timeDifference2 / (60 * 60 * 1000))).padStart(2, '0');
                              //                     minutes = String(Math.floor((timeDifference2 % (60 * 60 * 1000)) / (60 * 1000))).padStart(2, '0');
                              //                     let faultData = {
                              //                       Prekršaj: count,
                              //                       Vozač: findDriver(record.driver),
                              //                       Kompanija: company.Name,
                              //                       Poruka: `Nema dovoljni dnevni odmor dana ${formatSRBTime(nextRecord.startTime)}, trajao ${hours}:${minutes}!`
                              //                     }
                              //                     arrayFaults.push(faultData)
                              //                   } else {
                              //                     let faultData = {
                              //                       Prekršaj: count,
                              //                       Vozač: findDriver(record.driver),
                              //                       Kompanija: company.Name,
                              //                       Poruka: `Nema dovoljni dnevni odmor dana ${formatSRBTime(nextRecord.startTime)}, trajao ${hours}:${minutes}!`
                              //                     }
                              //                     arrayFaults.push(faultData)
                              //                   }
                              //                 }
                              //               } else {
                              //                 let faultData = {
                              //                   Prekršaj: count,
                              //                   Vozač: findDriver(record.driver),
                              //                   Kompanija: company.Name,
                              //                   Poruka: `Nema dovoljni dnevni odmor dana ${formatSRBTime(nextRecord.startTime)}, trajao ${hours}:${minutes}!`
                              //                 }
                              //                 arrayFaults.push(faultData)
                              //               }  
                              //           }
                              //       }
                              //     }
                              // }
                      } else if (timeDifference > 9 * 60 * 60 * 1000 && timeDifference < 11 * 60 * 60 * 1000) {
                        if(isGradski){
                          if(timeDifferenceDay < 9 * 60 * 60 * 1000 && endTimeRest > wholeDay24h) {
                            count++
                            let faultData = {
                              Prekršaj: count,
                              Vozač: findDriver(record.driver),
                              Kompanija: company.Name,
                              Poruka: `Nema devetku u 24h. Prekršaj dana ${formatSRBTime(record.endTime)}. Dnevni odmor u 24h je samo ${hoursDay}:${minutesDay}!`
                            }
                            arrayFaults.push(faultData)
                        }
                        } else {
                          count9h++
                              if(timeDifferenceDay < 9 * 60 * 60 * 1000 && endTimeRest > wholeDay24h) {
                                count++
                                let faultData;
                                if(count9h > 2) {
                                  faultData = {
                                    Prekršaj: count,
                                    Vozač: findDriver(record.driver),
                                    Kompanija: company.Name,
                                    Poruka: `Nema devetku u ${wholeDay24h == 24 * 60 * 60 * 1000 ? '24' : '30'}h. Prekršaj dana ${formatSRBTime(record.endTime)}. Ima 2 prekršaja u istom danu: 1. Dnevni odmor u ${wholeDay24h == 24 * 60 * 60 * 1000 ? '24' : '30'}h je samo ${hoursDay}:${minutesDay}, 2. Broj devetki za redom: ${count9h}!`
                                  }
                                } else {
                                  faultData = {
                                    Prekršaj: count,
                                    Vozač: findDriver(record.driver),
                                    Kompanija: company.Name,
                                    Poruka: `Nema devetku u ${wholeDay24h == 24 * 60 * 60 * 1000 ? '24' : '30'}h. Prekršaj dana ${formatSRBTime(record.endTime)}. Dnevni odmor u ${wholeDay24h == 24 * 60 * 60 * 1000 ? '24' : '30'}h je samo ${hoursDay}:${minutesDay}!`
                                  }
                                }
                                arrayFaults.push(faultData)
                            } else {
                              if(count9h > 2) {
                                count++
                                let faultData = {
                                  Prekršaj: count,
                                  Vozač: findDriver(record.driver),
                                  Kompanija: company.Name,
                                  Poruka: `Prekršaj dana ${formatSRBTime(record.endTime)}. Nema puni dnevni odmor u radnoj nedelji. Broj devetki za redom: ${count9h}!`
                                }
                                arrayFaults.push(faultData)
                              }
                            }
                        }
                  } else {
                      if(timeDifferenceDay < 9 * 60 * 60 * 1000 && endTimeRest > wholeDay24h) {
                        count++
                        let faultData = {
                          Prekršaj: count,
                          Vozač: findDriver(record.driver),
                          Kompanija: company.Name,
                          Poruka: `Nema devetku u ${wholeDay24h == 24 * 60 * 60 * 1000 ? '24' : '30'}h. Prekršaj dana ${formatSRBTime(record.endTime)}. Dnevni odmor u ${wholeDay24h == 24 * 60 * 60 * 1000 ? '24' : '30'}h je samo ${hoursDay}:${minutesDay}!`
                        }
                        arrayFaults.push(faultData)
                    }
                  }
              }
            }
          }
      }
      
      return arrayFaults;
    }

    const faults = calculate9orLess(arrayWorkDay)

    //Dodat deo da se obrise nemanje dnevnog odmora ako ima u UNKNOWN ostali rad poverzan vise od 5h
    if(resUnknown) {
     for(let i=0; i< faults.length; i++){
        for(let j=0; j<resUnknown.length;j++){

          if(faults[i]){
            if(faults[i].Vozač == resUnknown[j].Vozač){
              
                  if(resUnknown[j].Poruka.substr(33, 10) == faults[i].Poruka.substr(45, 10)){
              
                    faults.splice(i,1);
                  }   
              
                                               
        } 
  
          }
      }
      }
     
  }

    return faults;
}