export function processRecordsWorkDay(records, company, driversDB, isGradski, resSmallDayRest, resUnknown) {

    let count = 0;
    const activitiesToConsider = ['break/rest', 'driving', 'work', 'availability', 'unknown'];

    const findDriver = (id) => {
        let driver = driversDB.find(driver => driver.Id === id)
        return driver.FullName;
    }
    function formatTime(milliseconds) {
        const hours = String(Math.floor(milliseconds / (60 * 60 * 1000))).padStart(2, '0');
        const minutes = String(Math.floor((milliseconds % (60 * 60 * 1000)) / (60 * 1000))).padStart(2, '0');
        return `${hours}:${minutes}`;
      }
      function formatSRBTime(dateString) {
        const parts = dateString.split('-');
        const date = new Date(`${parts[1]}/${parts[2]}/${parts[0]}`);
        const formattedDate = `${date.getDate().toString().padStart(2, '0')}.${(date.getMonth() + 1).toString().padStart(2, '0')}.${date.getFullYear()}`;
        return formattedDate;
    }
      const arrayMoreThan9Hours = [];

      for(let n=0; n<driversDB.length;n++){
        let driver = driversDB[n];
        let filtered = records.filter(item => item.DriverId === driver.Id) 

    const groupedByDay = [];
    let arrayDay = { day: 0, totalTime: 0, driver: 0 };

    // Helper function to check if a record is a 'break/rest' with more than 8:30 duration
    const isLongBreakRest = (record) =>
    (record.Value.toLowerCase() === 'availability' || record.Value.toLowerCase() === 'break/rest' || record.Value.toLowerCase() === 'unknown') && parseInt(record.TotalTime) >= 7 * 60 * 60 * 1000;

                for (let i = 0; i < filtered.length; i++) {
                    const record = filtered[i];
                    const nextRecord = filtered[i+1];
                    const day = record.ActivityDate.split("T")[0];
                    

                    
                    if (activitiesToConsider.includes(record.Value.toLowerCase())) {
                        if (isLongBreakRest(record)) {
                            if(arrayDay.totalTime > 0) {
                                groupedByDay.push(
                                    {
                                        driver: arrayDay.driver,
                                        company: company.Name,
                                        day: arrayDay.day,
                                        totalTime: arrayDay.totalTime}
                                )
                                arrayDay = { day: 0, totalTime: 0, driver: 0 };
                            }
                        } else if (record.Value.toLowerCase() === 'driving') {
                            arrayDay.driver = findDriver(record.DriverId)
                            arrayDay.day = day;
                            arrayDay.totalTime += parseInt(record.TotalTime);


                                            if (!nextRecord) {
                                                groupedByDay.push(
                                                    {
                                                        driver: arrayDay.driver,
                                                        company: company.Name,
                                                        day: arrayDay.day,
                                                        totalTime: arrayDay.totalTime}
                                                )
                                                arrayDay = { day: 0, totalTime: 0, driver: 0 };
                                            } 
                                        
                        } else if (record.Value.toLowerCase() === 'break/rest'){
                            if(nextRecord) {
                                if(nextRecord.Value.toLowerCase() === 'break/rest'){
                                    if(parseInt(record.TotalTime) + parseInt(nextRecord.TotalTime) >= 7 * 60 * 60 * 1000) {
                                        groupedByDay.push(
                                            {
                                                driver: arrayDay.driver,
                                                company: company.Name,
                                                day: arrayDay.day,
                                                totalTime: arrayDay.totalTime}
                                        )
                                        arrayDay = { day: 0, totalTime: 0, driver: 0 };
                                    }
                                } else if(nextRecord.Value.toLowerCase() === 'unknown') {
                                    if(parseInt(record.TotalTime) + parseInt(nextRecord.TotalTime) >= 7 * 60 * 60 * 1000) {
                                        groupedByDay.push(
                                            {
                                                driver: arrayDay.driver,
                                                company: company.Name,
                                                day: arrayDay.day,
                                                totalTime: arrayDay.totalTime}
                                        )
                                        arrayDay = { day: 0, totalTime: 0, driver: 0 };
                                    }
                                } 
                            }
                        }  else if (record.Value.toLowerCase() === 'availability'){
                            if(nextRecord) {
                            if(nextRecord.Value.toLowerCase() === 'availability'){
                                if(parseInt(record.TotalTime) + parseInt(nextRecord.TotalTime) >= 7 * 60 * 60 * 1000) {
                                    groupedByDay.push(
                                        {
                                            driver: arrayDay.driver,
                                            company: company.Name,
                                            day: arrayDay.day,
                                            totalTime: arrayDay.totalTime}
                                    )
                                    arrayDay = { day: 0, totalTime: 0, driver: 0 };
                                }
                            }
                            }
                        }  else if (record.Value.toLowerCase() === 'unknown'){
                            if(nextRecord) {
                            if(nextRecord.Value.toLowerCase() === 'unknown'){
                                if(parseInt(record.TotalTime) + parseInt(nextRecord.TotalTime) >= 7 * 60 * 60 * 1000) {
                                    groupedByDay.push(
                                        {
                                            driver: arrayDay.driver,
                                            company: company.Name,
                                            day: arrayDay.day,
                                            totalTime: arrayDay.totalTime}
                                    )
                                    arrayDay = { day: 0, totalTime: 0, driver: 0 };
                                }
                            } else if(nextRecord.Value.toLowerCase() === 'break/rest') {
                                if(parseInt(record.TotalTime) + parseInt(nextRecord.TotalTime) >= 7 * 60 * 60 * 1000) {
                                    groupedByDay.push(
                                        {
                                            driver: arrayDay.driver,
                                            company: company.Name,
                                            day: arrayDay.day,
                                            totalTime: arrayDay.totalTime}
                                    )
                                    arrayDay = { day: 0, totalTime: 0, driver: 0 };
                                }
                            }
                            }
                        }
                        }
                    } 
 
        // Initialize the array to store summed values over 9 hours
                
            
                // Iterate through the groupedByDay object
                for (let i=0; i<groupedByDay.length; i++) {
                    let record = groupedByDay[i];
                            if(isGradski) {
                                    if (record.totalTime >= 8 * 60 * 60 * 1000 + 15 * 60 * 1000) { 

                                                count++
                                                arrayMoreThan9Hours.push({
                                                    Prekršaj: count,
                                                    Vozač: record.driver,
                                                    Kompanija: company.Name,
                                                    Poruka: `Kazna na dan ${formatSRBTime(record.day)} za prekoračenje vremena vožnje, ${formatTime(record.totalTime)}!`
                                                            });
                                                    
                                        }

                        
                            } else {
                            
                            if (record.totalTime > 9 * 60 * 60 * 1000) {
                            
                                if (record.totalTime > 9 * 60 * 60 * 1000 && record.totalTime <= 10 * 60 * 60 * 1000) {
                                    
                                    // Check three days in the future
                                    let pastDays = 6;
                                    let count10hourDriverDays = 1;

                                    for (let j = 1; j <= pastDays; j++) {
                                        let previousRecord = groupedByDay[i-j]
                                        
                                        if (previousRecord === undefined) {
                                            continue;
                                        } else if (previousRecord.totalTime > 9 * 60 * 60 * 1000 && previousRecord.totalTime <= 10 * 60 * 60 * 1000) {
                                            count10hourDriverDays++;
                                        } 
                                    }
                                    if(count10hourDriverDays > 2) {
                            
                                        count++
                                        arrayMoreThan9Hours.push({
                                            Prekršaj: count,
                                            Vozač: record.driver,
                                            Kompanija: company.Name,
                                            Poruka: `Kazna na dan ${formatSRBTime(record.day)} za prekoračenje vremena vožnje, 3 dan u nedelji po 10h voznje, ${formatTime(record.totalTime)}!`
                                                    });
                                    }
                                }  else if (record.totalTime > 10 * 60 * 60 * 1000) {
                                    if(resSmallDayRest) {
                                        for(let j=0;j < resSmallDayRest.length;j++){
                                            if(record.driver == resSmallDayRest[j].Vozač){
                                               if(resSmallDayRest[j].Poruka.substr(32, 10) != formatSRBTime(record.day)){
                                                count++
                                                arrayMoreThan9Hours.push({
                                                        Prekršaj: count,
                                                        Vozač: record.driver,
                                                        Kompanija: company.Name,
                                                        Poruka: `Kazna na dan ${formatSRBTime(record.day)} za prekoračenje vremena vožnje, ${formatTime(record.totalTime)}!`
                                                        });
                                                    }
                                               }
                                            }
                                    }
                                   
                                  
                                }    
                                    
                                }
                                }
                

                    }
      }
      //DODAT DEO DA SE IZBACI GRESKA UKOLIKO POSTOJI U UNKNOWN OSTALI RAD DUZI OD 5h ZA ISTI DAN
      if(resUnknown) {
      for(let i=0; i< arrayMoreThan9Hours.length; i++){

      
            for(let j=0; j<resUnknown.length;j++){

                if(arrayMoreThan9Hours[i]){
                    if(arrayMoreThan9Hours[i].Vozač == resUnknown[j].Vozač){
        
                        if(resUnknown[j].Poruka.substr(33, 10) == arrayMoreThan9Hours[i].Poruka.substr(13, 10)){
                     
                            arrayMoreThan9Hours.splice(i,1);
                          
                        }                                     
                } 
    
                }
          }
        }
       
    }
    //DODAT DEO DA SE IZBACI GRESKA UKOLIKO POSTOJI U PREKORACENJE RADNOG DANA, TJ AKO NEMA ODMORA
    if(resSmallDayRest) {
    for(let i=0; i< arrayMoreThan9Hours.length; i++){

    
            for(let j=0;j < resSmallDayRest.length;j++){

                if(arrayMoreThan9Hours[i]){
                    if(arrayMoreThan9Hours[i].Vozač == resSmallDayRest[j].Vozač){
        
                        if(resSmallDayRest[j].Poruka.substr(32, 10) == arrayMoreThan9Hours[i].Poruka.substr(13, 10)){
                     
                            arrayMoreThan9Hours.splice(i,1);
                          
                        }                                     
                } 
    
                }
          }
        }
      
    }
    
     
return arrayMoreThan9Hours;
}