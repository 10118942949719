import React, { useState, useEffect, useRef, useContext } from "react";
import "./Kazne.css";
import { Toast } from "primereact/toast";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import Loading from "../Loading/loading";
import { AuthContext } from "../Header/auth - context";
import { useSelector } from "react-redux";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import { AutoComplete } from "primereact/autocomplete";
import { Tooltip } from 'primereact/tooltip';
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from 'primereact/dropdown';
import {processCardExpiryDate} from './IZVESTAJI/faultCardExpiry';
import {processTwoWeekDriving} from './IZVESTAJI/faultTwoWeekDriving';
import {processRecordsOut} from './IZVESTAJI/faultOut';
import {processWeekRest} from './IZVESTAJI/faultWeekRest';
import {processSmallDayRest} from './IZVESTAJI/faultSmallDayRest';
import {processUnknown} from './IZVESTAJI/faultUnknown';
import {processRecords} from './IZVESTAJI/faultLess45min';
import {processRecordsWork} from './IZVESTAJI/faultSumWork';
import {processRecordsNightWork} from './IZVESTAJI/faultNightWork';
import {processRecordsWorkDay} from './IZVESTAJI/faultSumDriving';
import {processRecordsNightDriving} from './IZVESTAJI/faultNightDriving';
import { addLocale } from 'primereact/api';

const Kazne = () => {
  const [displayFaultsTable, setDisplayFaultsTable] = useState(false);
//   const company = useSelector(state => state.company)
  const [companyDB, setCompanyDB] = useState([]);
  const [driversDB, setDriversDB] = useState([]);
  const toast = useRef(null);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [first, setFirst] = useState(0);
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  // const [driverLabel, setDriverLabel] = useState(true);
  const [isGradski, setIsGradski] = useState(false);
//   const [employees, setEmployees] = useState([]);
  const [filteredCompany, setFilteredCompany] = useState(null);
  // const [filteredDrivers, setFilteredDrivers] = useState(null);
  const [company, setCompany] = useState(null);
  const [driver, setDriver] = useState(null);
  const [load, setLoad] = useState(true);
  const auth = useContext(AuthContext);
  const companyFromStore = useSelector((state) => state.company);
  const [selectedFault, setSelectedFault] = useState(0);
  const [dataTable, setDataTable] = useState([])

  addLocale('sr', {
    firstDayOfWeek: 1,
    showMonthAfterYear: true
});

  const faults = [
      { name: 'Vikend odmor', code: 0 },
      { name: 'Dnevni odmor', code: 1 },
      { name: 'Loše upravljanje tahografom', code: 2 },
      { name: 'Prekoračenje vožnje', code: 3 },
      { name: 'Dvonedeljno prekoračenje vožnje', code: 4 },
      { name: 'Ukupan rad', code: 5 },
      { name: 'Noćni rad', code: 6 },
      { name: 'Noćna vožnja', code: 7 },
      { name: 'OUT', code: 8 },
      { name: 'Pauza 45 minuta', code: 9 },
      { name: 'Istek kartica', code: 10 },
      { name: 'SVE', code: 11 }
  ];

  const [arrayDrivingTimeFault, setArrayDrivingTimeFault] = useState([]);
  const [arrayWeekRestFault, setArrayWeekRestFault] = useState([]);
  const [arrayRestLess9h, setArrayRestLess9h] = useState([]);
  const [arrayUnknown, setArrayUnknown] = useState([]);
  const [arrayLessThen45, setArrayLessThen45] = useState([]);
  const [arrayOut, setArrayOutFault] = useState([]);
  const [arrayTwoWeekDriving, setArrayTwoWeekDrivingFault] = useState([]);
  const [arrayWorkBreak, setArrayWorkBreakFault] = useState([]);
  const [arrayCardExpire, setArrayCardExpireFault] = useState([]);
  const [arrayNightWork, setArrayNightWorkFault] = useState([]);
  const [arrayNightDriving, setArrayNightDrivingFault] = useState([]);
  

  const handleErrors = (response) => {
    if (response.ok) {
      return response.json();
    } else {
      throw response;
    }
  };


  useEffect(() => {
    fetch(process.env.REACT_APP_BACKEND_URL + "/tacho")
    .then(handleErrors)
    .then((result) => {
      if(companyFromStore.companyId == 2) {
        //Ukoliko je user DUJIC KONSALTING, VRATI SVE
        setCompanyDB(result)
      } else {
        let filteredCompany =  result.filter(x => x.ID_TPServis == companyFromStore.companyId)
        setCompany(filteredCompany)
        returnDriversForCompany(filteredCompany[0].Id)
        setCompanyDB(filteredCompany)
      }
      setLoad(false)
      setDisplayFaultsTable(false)
    })
    .catch((error) => {
      error.json().then((errorMessage) => {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: errorMessage.message,
          life: 3000,
        });
        console.log(errorMessage);
      });
    });
  }, []); 

  const printFaultName = (data) => {
    const faultsName = [
      {
        Prekršaj: '',
        Vozač: '',
        Kompanija: '',
        Poruka: ''
      }
    ]
    
    switch (data) {
      case 'arrayCardExpire':
        faultsName[0].Prekršaj = 'ISTEK KARTICA'
      break;
      case 'arrayTwoWeekDriving':
        faultsName[0].Prekršaj = 'DVONEDELJNKO PREKORAČENJE VOŽNJE'
      break;
      case 'arrayOut':
        faultsName[0].Prekršaj = 'OUT'
      break;
      case 'arrayWeekRestFault':
        faultsName[0].Prekršaj = 'VIKEND ODMOR'
      break;
      case 'arrayRestLess9h':
        faultsName[0].Prekršaj = 'DNEVNI ODMOR'
      break;
      case 'arrayUnknown':
        faultsName[0].Prekršaj = 'LOŠE UPRAVLJANJE TAHOGRAFOM'
      break;
      case 'arrayLessThen45':
        faultsName[0].Prekršaj = 'PAUZA 45 MINUTA'
      break;
      case 'arrayWorkBreak':
        faultsName[0].Prekršaj = 'UKUPAN RAD'
      break;
      case 'arrayNightWork':
        faultsName[0].Prekršaj = 'NOĆNI RAD'
      break;
      case 'arrayNightDriving':
        faultsName[0].Prekršaj = 'NOĆNA VOŽNJA'
      break;
      case 'arrayDrivingTimeFault':
        faultsName[0].Prekršaj = 'PREKORAČENJE VOŽNJE'
      break;
      case 'resCard':
        faultsName[0].Prekršaj = 'ISTEK KARTICA'
      break;
      case 'resTwoWeekDriving':
        faultsName[0].Prekršaj = 'DVONEDELJNKO PREKORAČENJE VOŽNJE'
      break;
      case 'resOut':
        faultsName[0].Prekršaj = 'OUT'
      break;
      case 'resWeekRest':
        faultsName[0].Prekršaj = 'VIKEND ODMOR'
      break;
      case 'resSmallDayRest':
        faultsName[0].Prekršaj = 'DNEVNI ODMOR'
      break;
      case 'resUnknown':
        faultsName[0].Prekršaj = 'LOŠE UPRAVLJANJE TAHOGRAFOM'
      break;
      case 'res45break':
        faultsName[0].Prekršaj = 'PAUZA 45 MINUTA'
      break;
      case 'resWorkBreak':
        faultsName[0].Prekršaj = 'UKUPAN RAD'
      break;
      case 'resNightWork':
        faultsName[0].Prekršaj = 'NOĆNI RAD'
      break;
      case 'resNightDriving':
        faultsName[0].Prekršaj = 'NOĆNA VOŽNJA'
      break;
      case 'res9hWork':
        faultsName[0].Prekršaj = 'PREKORAČENJE VOŽNJE'
      break;
      default:
        faultsName[0].Prekršaj = ''
      break;
    }
   
    return faultsName
  }

  const checkArray = async (data) => {                  

    let check;
    const newData = [];
  
    for (let variableName in data) {
      if(data[variableName] === undefined){
            continue
          } else {
            if(data[variableName].length > 0){
              check++
              newData.push(...printFaultName(variableName))
              newData.push(...data[variableName]);
            }
          }
        }
      
    if(check === 0) {
      toast.current.show({
        severity: "info",
        summary: "Obaveštenje",
        detail: `Za taj vremenski period nema podataka vozača ${driver.FullName}`,
        life: 3000,
      });
    } else {
      await setDataTable(newData);
    } 
 
  }

  const setFaultsReport = (e) => {
    setDisplayFaultsTable(false)
    setDataTable([])
    e.preventDefault();
    if (company !== null && dateTo !== null && dateFrom !== null) {
      if (dateTo > dateFrom) {
        setLoad(true);
        const start = new Date(dateFrom);
        const end = new Date(dateTo);

        let loop = new Date(start);
        let arrayDates = [];
        while (loop <= end) {
          arrayDates.push(new Date(loop))
          let newDate = loop.setDate(loop.getDate() + 1);
          loop = new Date(newDate);

        }
        
        let newDateTO = new Date(dateTo);
        newDateTO.setHours(newDateTO.getHours() + 2);
        let stavkaTO = newDateTO.toISOString().substr(0, 10);

        let newDateFrom = new Date(dateFrom);
        newDateFrom.setHours(newDateFrom.getHours() + 2);
        let stavkaFROM = newDateFrom.toISOString().substr(0, 10);
          if(newDateTO.getTime() <= new Date().getTime()) {
                  if(driver){
                    let requestOptions = {
                        method: "POST",
                        headers: {
                          "Content-Type": "application/json",
                          Authorization: "Bearer " + auth.token,
                        },
                        body: JSON.stringify({
                            idDriver: driver.Id,
                            dateFrom: stavkaFROM,
                            dateTo: stavkaTO,
                            isGradski: isGradski
                        }),
                      };
                      fetch(process.env.REACT_APP_BACKEND_URL + "/tacho/faultsByDriver", requestOptions)
                      .then(handleErrors)
                      .then((res) => {
                        setGlobalFilter('')
                        let result = res[0];
                        let result1 = res[1];
                        let result2 = res[2];
                          
                          // console.log('Racuna istek kartica')
                          const resCard = processCardExpiryDate(result1, company, driversDB)
                          // console.log(resCard);
                          setArrayCardExpireFault(resCard)
                        
                          // console.log('Racuna dvonedeljno radno vreme')
                          const resTwoWeekDriving = processTwoWeekDriving(result, company, driversDB)
                          // console.log(resTwoWeekDriving);
                          setArrayTwoWeekDrivingFault(resTwoWeekDriving)
                          // racuna nedostatak OUT
                          // console.log('Racuna nedostatak OUTa')
                          const resOut = processRecordsOut(result, result2, company, driversDB, isGradski)
                          // console.log(resOut);
                          setArrayOutFault(resOut)
                        
                          // racuna nedeljni odmor COMPLETE
                            
                          const resWeekRest = processWeekRest(result, company, driversDB, isGradski, dateTo);
                          // console.log('Manji nedeljni odmor')
                          // console.log(resWeekRest);
                          setArrayWeekRestFault(resWeekRest)

                          
                          const resUnknown = processUnknown(result, company, driversDB)
                          // console.log('Racuna Nepoznato')
                          // console.log(resUnknown);
                          setArrayUnknown(resUnknown)
                          
                          const resSmallDayRest = processSmallDayRest(result, company, driversDB, isGradski, resUnknown);
                          // console.log('Racuna manje od 9h odmora')
                          // console.log(resSmallDayRest);
                          setArrayRestLess9h(resSmallDayRest)
                        // racuna pauze manje od 45min COMPLETE
                          const res45break = processRecords(result, company, driversDB, isGradski);
                          // console.log('Racuna manje od 45min pauze')
                          // console.log(res45break);
                          setArrayLessThen45(res45break)

                          // racuna ukupan rad 6h+ COMPLETE
                          const resWorkBreak = processRecordsWork(result, company, driversDB, isGradski);
                          // console.log('Racuna manju pauzu UKUPAN RAD')
                          // console.log(resWorkBreak);
                          setArrayWorkBreakFault(resWorkBreak)


                            // racuna Nocni rad COMPLETE
                          const resNightWork = processRecordsNightWork(result, company, driversDB, isGradski);
                          // console.log('Racuna manju pauzu NOCNI RAD')
                          // console.log(resNightWork);
                          setArrayNightWorkFault(resNightWork)

                          // racuna Nocnu voznju COMPLETE
                          const resNightDriving = processRecordsNightDriving(result, company, driversDB, isGradski);
                          // console.log('Racuna manju pauzu NOCNI RAD')
                          // console.log(resNightWork);
                          setArrayNightDrivingFault(resNightDriving)
                        

                          // racuna radno vreme prekoracenja COMPLETE
                          // console.log('Racuna prekoracenje radnog vremena')
                          const res9hWork = processRecordsWorkDay(result, company, driversDB, isGradski, resSmallDayRest, resUnknown)
                          // console.log(res9hWork);
                          setArrayDrivingTimeFault(res9hWork)

                        setSelectedFault({ name: 'SVE', code: 11 })
                
                      const allArrays = {  resCard,
                        resTwoWeekDriving,
                        resOut,
                        resWeekRest,
                        resSmallDayRest,
                        resUnknown,
                        res45break,
                        resWorkBreak,
                        resNightWork,
                        resNightDriving,
                        res9hWork };

                       

                        checkArray(allArrays);
                        setDisplayFaultsTable(true);
                        setLoad(false);
                        setFirst(0);
                      })
                      .catch((error) => {
                        error.json().then((errorMessage) => {
                          toast.current.show({
                            severity: "error",
                            summary: "Error",
                            detail: errorMessage.message,
                            life: 3000,
                          });
                          console.log(errorMessage);
                          setLoad(false)
                        });
                      });
                } else {
                    let requestOptions = {
                        method: "POST",
                        headers: {
                          "Content-Type": "application/json",
                          Authorization: "Bearer " + auth.token,
                        },
                        body: JSON.stringify({
                          idCompany: company.Id,
                          dateFrom: stavkaFROM,
                          dateTo: stavkaTO,
                          isGradski: isGradski
                        }),
                      };
                      fetch(process.env.REACT_APP_BACKEND_URL + "/tacho/faultsByCompany", requestOptions)
                      .then(handleErrors)
                      .then((res) => {
                        setGlobalFilter('')
                        let result = res[0];
                        let result1 = res[1];
                        let result2 = res[2];


                          // console.log('Racuna istek kartica')
                          const resCard = processCardExpiryDate(result1, company, driversDB)
                          // console.log(resCard);
                          setArrayCardExpireFault(resCard)
                        
                          // console.log('Racuna dvonedeljno radno vreme')
                          const resTwoWeekDriving = processTwoWeekDriving(result, company, driversDB)
                          // console.log(resTwoWeekDriving);
                          setArrayTwoWeekDrivingFault(resTwoWeekDriving)
                          // racuna nedostatak OUT
                          // console.log('Racuna nedostatak OUTa')
                          const resOut = processRecordsOut(result, result2, company, driversDB, isGradski)
                          // console.log(resOut);
                          setArrayOutFault(resOut)
                        
                          // racuna nedeljni odmor COMPLETE
                            
                          const resWeekRest = processWeekRest(result, company, driversDB, isGradski);
                          // console.log('Manji nedeljni odmor')
                          // console.log(resWeekRest);
                          setArrayWeekRestFault(resWeekRest)

                          const resUnknown = processUnknown(result, company, driversDB)
                          // console.log('Racuna Nepoznato')
                          // console.log(resUnknown);
                          setArrayUnknown(resUnknown)     

                          const resSmallDayRest = processSmallDayRest(result, company, driversDB, isGradski, resUnknown);
                          // console.log('Racuna manje od 9h odmora')
                          // console.log(resSmallDayRest);
                          setArrayRestLess9h(resSmallDayRest)
                          
                        // racuna pauze manje od 45min COMPLETE
                          const res45break = processRecords(result, company, driversDB, isGradski);
                          // console.log('Racuna manje od 45min pauze')
                          // console.log(res45break);
                          setArrayLessThen45(res45break)

                          // racuna ukupan rad 6h+ COMPLETE
                          const resWorkBreak = processRecordsWork(result, company, driversDB, isGradski);
                          // console.log('Racuna manju pauzu UKUPAN RAD')
                          // console.log(resWorkBreak);
                          setArrayWorkBreakFault(resWorkBreak)


                            // racuna Nocni rad COMPLETE
                          const resNightWork = processRecordsNightWork(result, company, driversDB, isGradski);
                          // console.log('Racuna manju pauzu NOCNI RAD')
                          // console.log(resNightWork);
                          setArrayNightWorkFault(resNightWork)
                          const resNightDriving = processRecordsNightDriving(result, company, driversDB, isGradski);
                          // console.log('Racuna manju pauzu NOCNI RAD')
                          // console.log(resNightWork);
                          setArrayNightDrivingFault(resNightDriving)
                        

                          // racuna radno vreme prekoracenja COMPLETE
                          // console.log('Racuna prekoracenje radnog vremena')
                          const res9hWork = processRecordsWorkDay(result, company, driversDB, isGradski, resSmallDayRest, resUnknown)
                          // console.log(res9hWork);
                          setArrayDrivingTimeFault(res9hWork)

                        setSelectedFault({ name: 'SVE', code: 11 })
                        
                        const allArrays = {  resCard,
                          resTwoWeekDriving,
                          resOut,
                          resWeekRest,
                          resSmallDayRest,
                          resUnknown,
                          res45break,
                          resWorkBreak,
                          resNightWork,
                          resNightDriving,
                          res9hWork };

                        checkArray(allArrays)
                        setDisplayFaultsTable(true);
                        setLoad(false);
                        setFirst(0);
                      })
                      .catch((error) => {
                        error.json().then((errorMessage) => {
                          toast.current.show({
                            severity: "error",
                            summary: "Error",
                            detail: errorMessage.message,
                            life: 3000,
                          });
                          console.log(errorMessage);
                          setLoad(false)
                        });
                      });
              
                }
          } else {
            setLoad(false);
            toast.current.show({
              severity: "warn",
              summary: "Upozorenje!",
              detail: "Datum Do ne može biti u budućnosti!",
              life: 3000,
            })
          
          }
        } else {
          setLoad(false);
            toast.current.show({
                severity: "warn",
                summary: "Upozorenje!",
                detail: "Datum DO mora biti veći od Datuma OD!",
                life: 3000,
              })
            }

    } else {
      setLoad(false);
        toast.current.show({
            severity: "warn",
            summary: "Upozorenje!",
            detail: "Niste izabrali FIRMU ili datume!",
            life: 3000,
          });
    }
}

const searchCompany = (event) => {
    let query = event.query;
    let _filteredCompany = [];
    let filteredItems = companyDB.filter(
      (item) =>
        item.Name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
    if (filteredItems && filteredItems.length) {
        _filteredCompany.push(...filteredItems);
    }

    setFilteredCompany(_filteredCompany);
  };

const returnDriversForCompany = (idCompany) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + auth.token,
    },
    body: JSON.stringify({ 
      id: idCompany
     }),
  };
  fetch(process.env.REACT_APP_BACKEND_URL + "/tacho/drivers", requestOptions)
    .then(handleErrors)
    .then((result) => {
      let _drivers = [];
          for (let driver of result) {
              let _driver = {
                  Id: driver.Id,
                  FullName: driver.LastName + ' ' + driver.FirstName,
                  CompanyId: driver.CompanyId 
              };
              _drivers.push(_driver);
          }
      setDriversDB(_drivers);
    })
    .catch((error) => {
      error.json().then((errorMessage) => {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: errorMessage.message,
          life: 3000,
        });
        console.log(errorMessage);
        setLoad(false)
      });
    });

}
const stavkaCompanySelectFUNC = (e) => {
    setCompany(e.value);
    // setDriverLabel(false);
    if(e.value.Id){
      returnDriversForCompany(e.value.Id)
    }
  };
  const handleChange = (e) => {
    setDriversDB([]);
    setDriver(null);
    // setDriverLabel(true);
    setCompany(e);

  }
  // const searchDrivers = (event) => {
  //   let query = event.query;
  //   let _filteredDriver = [];
  //   let filteredItems = driversDB.filter(
  //     (item) =>
  //       item.FullName.toLowerCase().indexOf(query.toLowerCase()) !== -1
  //   );
  //   if (filteredItems && filteredItems.length) {
  //       _filteredDriver.push(...filteredItems);
  //   }

  //   setFilteredDrivers(_filteredDriver);
  // };
  const stavkaDriverSelectFUNC = (e) => {
    setDriver(e.value);
  };
  const handleDriverChange = (e) => {
    setDriver(e);
  }
  const clearData = () => {
    // setDriverLabel(true);
    setDriversDB([]);
    setCompany(null);
    setDriver(null);
    }

const exportExcel = () => {
        import('xlsx').then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(dataTable);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, {
                bookType: 'xlsx',
                type: 'array'
            });
            // saveAsExcelFile(excelBuffer, `${company.Name}`);
            saveAsExcelFile(excelBuffer, `KAZNE`);
        });
    };
const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then((module) => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    };
    const header = (
        <>
       
                <div
                className="faults_input"
                style={{ textAlign: "right", marginRight: "1%" }}
              >
                <i className="pi pi-search" style={{ margin: "8px 8px 0 0" }}></i>
                <input
                  type="search"
                  onInput={(e) => setGlobalFilter(e.target.value)}
                  placeholder="Pretraga"
                  size="100"
                />
              </div>
              <div className="flex align-items-center justify-content-end gap-2">
            <Button type="button" icon="pi pi-file-excel" severity="success" rounded onClick={exportExcel} data-pr-tooltip="XLS" />
        </div>
        </>

    );
  
    const changeDataTable = (e) => {
      setSelectedFault(e)

      const allArrays = {  
        arrayCardExpire,
        arrayTwoWeekDriving,
        arrayOut,
        arrayWeekRestFault,
        arrayRestLess9h,
        arrayUnknown,
        arrayLessThen45,
        arrayWorkBreak,
        arrayNightWork,
        arrayNightDriving,
        arrayDrivingTimeFault
        };

      switch (e.code) {
            case 0:
            setDataTable(arrayWeekRestFault)
            break;
            case 1:
            setDataTable(arrayRestLess9h)
            break;
            case 2:
            setDataTable(arrayUnknown)
            break;
            case 3:
            setDataTable(arrayDrivingTimeFault)
            break;
            case 4:
            setDataTable(arrayTwoWeekDriving)
            break;
            case 5:
            setDataTable(arrayWorkBreak)
            break;
            case 6:
            setDataTable(arrayNightWork)
            break;
            case 7:
            setDataTable(arrayNightDriving)
            break;
            case 8:
            setDataTable(arrayOut)
            break;
            case 9:
            setDataTable(arrayLessThen45)
            break;
            case 10:
            setDataTable(arrayCardExpire)
            break;
            case 11:
            checkArray(allArrays)
            break;
        default:
            checkArray(allArrays)
            break;
    }
    setFirst(0)
    }

  return (
    <div id="zaposleni_main">
       {load && <Loading/>}
      <div className="zaposleni_pregled">
        <p>PREKRŠAJI ZA PREVOZNIKA</p>
      </div>
      <div id="izvestaj_kazne_comteiner">
      <Toast ref={toast} />
        <div id="izvestaj_kazne_form_part1">
        <form>
          {companyFromStore.companyId == 2 &&      <><AutoComplete
                value={company}
                tooltip="Naziv kompanije"
                tooltipOptions={{ className: 'bluegray-tooltip', position: 'top' }}
                placeholder="Naziv kompanije"
                inputStyle={{ width: "100%", margin: "0" }}
                className="errorInput input_kazne_kompanija"
                suggestions={filteredCompany}
                completeMethod={searchCompany}
                field="Name"
                onClear={clearData}
                onSelect={stavkaCompanySelectFUNC}
                onChange={(e) => handleChange(e.value)}
              /><span className="errorInputName"></span></> }
        {/* <AutoComplete
                value={driver}
                tooltip="Naziv vozača"
                tooltipOptions={{ className: 'bluegray-tooltip', position: 'top' }}
                placeholder="Naziv vozača"
                inputStyle={{ width: "100%", margin: "0" }}
                className="errorInput input_kazne_kompanija"
                suggestions={filteredDrivers}
                completeMethod={searchDrivers}
                disabled={driverLabel}
                field="FullName"
                onClear={() => setDriver(null)}
                onSelect={stavkaDriverSelectFUNC}
                onChange={(e) => handleDriverChange(e.value)}
              />
              <span className="errorInputDriver"></span> */}
              <Dropdown 
              value={driver} 
              tooltip="Naziv vozača"
              tooltipOptions={{ className: 'bluegray-tooltip', position: 'top' }}
              inputStyle={{ width: "100%", margin: "0" }}
              onChange={(e) => handleDriverChange(e.value)} 
              options={driversDB}
              field="Id" 
              optionLabel="FullName" 
              onClear={() => setDriver(null)}
              editable 
              filter
              onSelect={stavkaDriverSelectFUNC}
              placeholder="Naziv vozača" 
              className="errorInput input_kazne_kompanija" />

          <Calendar
            value={dateFrom}
            onChange={(e) => setDateFrom(e.value)}
            placeholder="Datuma OD"
            showIcon
            className="kazne_izvestaj_form"
            dateFormat="dd.mm.yy"
            tooltip="Datum OD"
            tooltipOptions={{ className: 'bluegray-tooltip', position: 'top' }}
            locale="sr" 
          ></Calendar>
          <Calendar
            value={dateTo}
            onChange={(e) => setDateTo(e.value)}
            placeholder="Datuma DO"
            showIcon
            className="kazne_izvestaj_form"
            dateFormat="dd.mm.yy"
            tooltip="Datum DO"
            tooltipOptions={{ className: 'bluegray-tooltip', position: 'top' }}
            locale="sr"
          ></Calendar>
             <Checkbox
                    id="checked"
                    name="checked"
                    checked={isGradski}
                    tooltip="Gradski"
                    tooltipOptions={{ className: 'bluegray-tooltip', position: 'top' }}
                    className="checkGradski"
                    onChange={() => {
                        setIsGradski(prevCheck => !prevCheck);
                    }}
                ></Checkbox>
         
        </form>
        </div>
        <div id="izvestaj_kazne_form_part2">
            <Button
              onClick={setFaultsReport}
              label="Generiši Prekršaje"
              className="p-button-raised p-button-success p-button"
              id="dopuna_pumpe_button"
            />
        </div>
      </div>
      <div id="dropdownFaults">
        <div id="izvestaj_kazne_form_part1">
        <form>
             <Dropdown value={selectedFault} 
             onChange={(e) => changeDataTable(e.value)} 
             options={faults} 
             optionLabel="name" 
            placeholder="Izveštaj" 
            className="dropFault" 
            tooltip="Izveštaj prekršaja"
            tooltipOptions={{ className: 'bluegray-tooltip', position: 'top' }}/>
        </form>
        </div>
      </div>
      <Toast ref={toast} />
      {displayFaultsTable && <div className="faults_table">
            <Tooltip target=".export-buttons>button" position="bottom" />
        <DataTable
          value={dataTable}
          paginator
          first={first}
          rows={21}
          globalFilter={globalFilter}
          onPage={(e) => setFirst(e.first)}
          header={header}
        >
          <Column field="Prekršaj" header="Prekršaj" className="faultsTableFirstColumn"></Column>
          <Column field="Kompanija" header="Kompanija"></Column>
          <Column sortable field="Vozač" header="Vozač"></Column>
          <Column sortable field="Poruka" header="Poruka"></Column>
        </DataTable>
      </div>
      
      }
    </div>
  );
};

export default Kazne;
